import { onlineStatisticsFeature } from './+state/online-statistics.reducer';
import { OnlineStatisticsEffects } from './+state/online-statistics.effects';
import { OnlineStatisticsService } from './service/online-statistics.service';
import { OnlineStatisticsFacade } from './+state/online-statistics.facade';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OtherUserService } from 'other-user';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(onlineStatisticsFeature),
    EffectsModule.forFeature([OnlineStatisticsEffects]),
  ],
  providers: [OnlineStatisticsFacade, OnlineStatisticsService, OtherUserService],
})
export class SharedDataAccessOnlineStatisticsModule {}
