export enum BackendUserUpdateType {
  USERNAME = 'username',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  USER_FOOTBALL_ROLE = 'userFootballRole',
  USER_FOOTBALL_PLAYER_POSITION = 'userFootballPlayerPosition',
  USER_FAVOURITE_CLUB = 'userFavouriteClub',
  EMAIL = 'email',
  YEAR_OF_BIRTH = 'yearOfBirth',
  LANGUAGE = 'language',
  NOTIFICATION_SETTINGS = 'notificationSettings',
  CHALLENGEABILITY_SETTINGS = 'challengeabilitySettings',
}
