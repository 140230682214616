import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { Observable, of } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { UserOwnerView } from '@kiq/shared/classes';

@Injectable()
export class TranslationService {
  private readonly http = inject(HttpClient);
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private translocoService = inject(TranslocoService);
  private readonly BACKEND_TROPHY_URL = `${this.BACKEND_BASE_URL}/api/v1/user/trophies`;

  setSelectedLanguage(selectedLanguage: string | null) {
    if (selectedLanguage) {
      this.translocoService.setActiveLang(selectedLanguage);
    }
  }

  getSelectedLanguageFromUser(user: UserOwnerView): Observable<string | undefined> {
    const lastLanguageSelected = user.language;
    if (!lastLanguageSelected) {
      return of(this.translocoService.getActiveLang());
    }

    this.translocoService.setActiveLang(lastLanguageSelected);
    return of(lastLanguageSelected);
  }

  getActiveBrowserLanguage(): Observable<string | undefined> {
    return of(this.translocoService.getActiveLang());
  }

  getSelectedLanguageFromLocalStorage(): Observable<string | undefined> {
    const lastLanguageSelected = localStorage.getItem('lastLanguageSelected');
    if (!lastLanguageSelected) {
      return of(this.translocoService.getActiveLang());
    }

    const parsedLastSelectedLanguage = JSON.parse(lastLanguageSelected);
    this.translocoService.setActiveLang(parsedLastSelectedLanguage);
    return of(parsedLastSelectedLanguage);
  }

  setSelectedLanguageToLocalStorage(selectedLanguage: string | null) {
    if (selectedLanguage) {
      this.translocoService.setActiveLang(selectedLanguage);
      localStorage.setItem('lastLanguageSelected', JSON.stringify(selectedLanguage));
    }
  }
}
