import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationState, selectNotificationsModalToken } from './notification.reducer';
import { NotificationActions } from './notification.actions';

@Injectable({ providedIn: 'root' })
export class NotificationFacade {
  private store: Store<NotificationState> = inject(Store);

  selectNotificationsModalToken$ = this.store.select(selectNotificationsModalToken);

  setNotificationsModalTokenToLocalStorage(notificationsModalShown: boolean) {
    this.store.dispatch(
      NotificationActions.setNotificationModalTokenStart({ notificationsModalShown: notificationsModalShown }),
    );
  }

  getNotificationsModalTokenFromLocalStorage() {
    this.store.dispatch(NotificationActions.retrieveNotificationModalTokenStart());
  }
}
