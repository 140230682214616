<ng-container *transloco="let t; prefix: 'authentication.login'">
  <div class="background-stripes with-dark-gradient bg-cover bg-no-repeat w-full" id="login">
    <div class="bg-primary border-b border-surfaceSecondary absolute top-0 w-full">
      <div class="p-4 h-12 flex items-center">
        <button class="h-6 w-6" (click)="closeDialog()">
          <shared-icon class="icon-wrapper" name="close-round"></shared-icon>
        </button>
        <p class="text-textPrimary mx-auto brand text-base">{{ t("login") }}</p>
      </div>
    </div>

    <div class="relative overflow-y-auto px-4 py-4 mt-[48px] bg-no-repeat bg-cover bg-center custom-height w-full">
      <div class="max-w-default">
        <form [formGroup]="loginForm">
          <div class="flex flex-col gap-y-2 w-full">
            <kiq-text-input
              class="w-full"
              [inputType]="'email'"
              [autocomplete]="'username'"
              (focusin)="focus.set(1)"
              (keydown.enter)="focus.set(2)"
              formControlName="email"
              placeholder="{{ t('mailPlaceholder') }}"></kiq-text-input>
            <kiq-text-input
              class="w-full"
              [inputType]="'password'"
              [autocomplete]="'current-password'"
              [blur]="focusOut()"
              [shouldFocus]="focus() === 2"
              (keydown.enter)="focusOut.set(true)"
              (focusout)="focus.set(0)"
              (focusin)="focusOut.set(false); focus.set(2)"
              formControlName="password"
              placeholder="{{ t('passwordPlaceholder') }}"></kiq-text-input>
            <div class="w-full h-14">
              <client-button
                [loading]="(loading$ | async) ?? undefined"
                [buttonType]="'primary'"
                [disabled]="loginForm.invalid"
                [withShadow]="true"
                (click)="login()">
                <p class="large">{{ t("login") }}</p>
              </client-button>
            </div>
          </div>
        </form>
        <div class="flex flex-col text-white mt-2 items-center gap-y-4">
          <p class="underline mb-2 cursor-pointer" (click)="openPasswordResetDialog()">{{ t("forgottenPassword") }}</p>
          <p>{{ t("orWith") }}</p>
          <div class="w-full h-14">
            <client-button [buttonType]="'secondary'" [withShadow]="true" (click)="loginWithGoogle()">
              <div class="flex justify-center items-center gap-x-2">
                <img class="h-6 w-6 object-contain" [src]="GOOGLE_LOGO" />
                <p class="large">{{ t("google") }}</p>
              </div>
            </client-button>
          </div>

          <div class="w-full h-14">
            <client-button [buttonType]="'secondary'" [withShadow]="true" (click)="loginWithApple()">
              <div class="flex justify-center items-center gap-x-2">
                <img class="h-6 w-6 object-contain" [src]="APPLE_LOGO" />
                <p class="large">{{ t("apple") }}</p>
              </div>
            </client-button>
          </div>
          <p>
            {{ t("noAccount") }}
            <a class="underline pl-2 cursor-pointer" (click)="openRegister()">{{ t("register") }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
