<ng-container *transloco="let t; prefix: 'authentication.passwordReset'">
  <form [formGroup]="pwResetForm">
    <div class="background-stripes with-dark-gradient bg-cover bg-no-repeat h-full" id="password-reset">
      <div class="bg-primary border-b border-surfaceSecondary">
        <div class="p-4 h-12 max-w-default flex items-center">
          <button class="h-6 w-6" (click)="closeDialog()" type="button">
            <shared-icon class="icon-wrapper" name="close-round"></shared-icon>
          </button>
          <p class="text-textPrimary mx-auto brand text-base">{{ t('title') }}</p>
        </div>
      </div>

      <div class="p-4 bg-no-repeat bg-cover bg-center">
        <div class="h-full flex flex-col gap-6 container max-w-default text-white py-6">
          <div class="flex flex-col gap-6 h-full">
            <p>
              {{ t('description') }}
            </p>

            <kiq-text-input
              class="w-full"
              [formControlName]="'email'"
              placeholder="{{ t('currentMailPlaceholder') }}"></kiq-text-input>
          </div>

          <div class="w-full h-16">
            <client-button [buttonType]="'secondary'" [disabled]="pwResetForm.invalid" (click)="resetPassword()"
            ><p class="large"> {{ t('button') }}</p>
            </client-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
