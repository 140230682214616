import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NotificationActions = createActionGroup({
  source: 'notification',
  events: {
    'Set notification modal token start': props<{ notificationsModalShown: boolean }>(),
    'Set notification modal token success': props<{ notificationsModalShown: boolean }>(),
    'Set notification modal token fail': emptyProps(),

    'retrieve notification modal token start': emptyProps(),
    'retrieve notification modal token success': props<{ notificationsModalShown: boolean }>(),
    'retrieve notification modal token fail': emptyProps(),
  },
});
