import { AbstractControl } from '@angular/forms';

export class NumberValidator {
  static number(control: AbstractControl) {
    const val = control.value;
    const regex = /^([0-9]*)$/;
    if (val === null || val === '') return null;
    if (!val.toString().match(regex)) return { invalidNumber: true };
    return null;
  }
}
