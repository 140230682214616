import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  Injector,
  Input,
  OnChanges,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InGameModalConfig } from '@kiq/shared/types';
import { slideUpChild } from '@kiq/shared/ui/animations';
import { DynamicChildLoaderDirective } from '../../directives/load-child.directive';

@Component({
  selector: 'in-game-modal-wrapper',
  standalone: true,
  imports: [CommonModule, DynamicChildLoaderDirective],
  templateUrl: './in-game-modal-wrapper.component.html',
  styleUrl: './in-game-modal-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideUpChild],
})
export class InGameModalWrapperComponent implements OnChanges {
  @HostBinding('@slideUpChild') tooltipAnimation = true;

  @HostBinding('class') get getClasses() {
    if (this.isFullModalHeight()) {
      return 'absolute inset-0 z-30 block';
    } else {
      return 'absolute bottom-0 left-0 right-0 z-30 block';
    }
  }

  private readonly injector = inject(Injector);
  private readonly cdr = inject(ChangeDetectorRef);

  private isFullModalHeight = signal(false);

  @Input() config: InGameModalConfig | undefined;

  @ViewChild(DynamicChildLoaderDirective, { static: true }) dynamicChildLoader!: DynamicChildLoaderDirective;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['config']?.currentValue) {
      this.createComponent();

      this.isFullModalHeight.set(this.config?.isFullModalHeight ?? false);
    }
  }

  private createComponent() {
    if (!this.config?.component) return;

    this.dynamicChildLoader?.viewContainerRef.clear();

    const injector = Injector.create({
      parent: this.injector,
      providers: [],
    });
    const componentRef = this.dynamicChildLoader.viewContainerRef.createComponent(this.config.component, {
      injector,
    });

    // Pass inputs to the component. Ths is useful if the component requires inputs. You can pass them in the modal config.
    // Inputs are not required for the modal to work. You can also pass data via the data injection token.
    if (this.config?.inputs) {
      Object.keys(this.config.inputs).forEach((key) => {
        componentRef.setInput(key, this.config!.inputs![key]);
      });
    }

    this.cdr.detectChanges();
  }
}
