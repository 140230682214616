import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import {
  UserGameStatisticOpponentDetail,
  UserGameStatisticOpponentOverview,
  UserGameStatisticOverview,
} from '@kiq/shared/types';
import { Observable } from 'rxjs';

@Injectable()
export class OnlineStatisticsService {
  private readonly http = inject(HttpClient);

  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1/user`;
  private readonly BACKEND_URL_STATISTIC = '/gameStatistics';
  private readonly BACKEND_URL_STATISTIC_OPPONENT = '/opponent';

  private static getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  // own user
  //list of opponents
  getAllUserGameStatisticOpponentOverviews(): Observable<Array<UserGameStatisticOpponentOverview>> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url = this.BACKEND_URL + this.BACKEND_URL_STATISTIC;
    return this.http.get<Array<UserGameStatisticOpponentOverview>>(url, httpOptions);
  }

  //overview
  getOwnUserStatisticOverview(userId: string): Observable<UserGameStatisticOverview> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url = this.BACKEND_URL + '/' + userId + this.BACKEND_URL_STATISTIC;
    return this.http.get<UserGameStatisticOverview>(url, httpOptions);
  }

  //for other users
  //other users overview
  getUserGameStatisticOpponentOverview(otherUsersId: string): Observable<UserGameStatisticOverview> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url = this.BACKEND_URL + '/' + otherUsersId + this.BACKEND_URL_STATISTIC;
    return this.http.get<UserGameStatisticOpponentOverview>(url, httpOptions);
  }

  //details of opponent against own user
  getAllUserGameStatisticOpponentDetails(otherUsersId: string): Observable<Array<UserGameStatisticOpponentDetail>> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url =
      this.BACKEND_URL + this.BACKEND_URL_STATISTIC + this.BACKEND_URL_STATISTIC_OPPONENT + '/' + otherUsersId;
    return this.http.get<Array<UserGameStatisticOpponentDetail>>(url, httpOptions);
  }
}
