export enum GameTopscorerQuestionType {
  CHAMPIONS_DE = 'championsDe',
  CLUB_MEMBERS_DE = 'clubMembersDe',
  GERMAN_NATIONAL_TEAMPLAYER_OCCURRENCES = 'germanNationalTeamPlayerOccurrences',
  GERMAN_PLAYER_OCCURRENCES_DE = 'germanPlayerOccurrencesDe',
  RED_CARDS_DE = 'redCardsDe',
  STADIUM_SIZE_DE = 'stadiumSizeDe',
  TOP_TRANSFERS = 'topTransfers',
  TOPSCORER_DE = 'topscorerDe',
  STADIUM_SIZE_EN = 'stadiumSizeEn',
  STRONG_FOOT = 'playerPreferredFoot',
  PLAYERS_AGE = 'playerAge',
}
