<div
  class="flex text-gray-200 items-center border border-grey14 hover:border-white rounded bg-surfaceSecondary px-3 py-3.5 gap-2">
  <div
    class="bg-surfaceSecondary w-full text-base font-normal"
    [class.text-neutral-400]="!control.value"
    [class.text-white]="control.value">
    @if (control.value) {
      {{ control.value }}
    } @else {
      {{ placeholder }}
    }
  </div>
</div>
<ng-container *transloco="let t; prefix: 'inputs.textInput'">
  <label class="label" *ngIf="(hint?.length ?? 0) > 0"
    ><span class="label-text-alt text-white">{{ hint }}</span></label
  >

  <label *ngIf="errorTypes.includes('required') && control?.hasError('required') && control?.dirty && control?.touched">
    <span class="label-text-alt text-red-600">{{ t("requiredError") }}</span>
  </label>
</ng-container>
