import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserBackendService } from '@kiq/client/data-access/user';
import { notificationFeature } from './+state/notification.reducer';
import { NotificationEffects } from './+state/notification.effects';
import { NotificationFacade } from './+state/notification.facade';
import { AppNotificationService } from './service/app-notification.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    StoreModule.forFeature(notificationFeature),
    EffectsModule.forFeature([NotificationEffects]),
  ],
  providers: [UserBackendService, NotificationFacade, AppNotificationService],
})
export class SharedDataAccessNotificationModule {}
