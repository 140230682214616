import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../../base-input.component';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/client/util/translation';

@Component({
  selector: 'kiq-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'inputs',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class TextInputComponent extends BaseInputComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() prefixIconName: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() inputType: 'password' | 'email' | 'text' | 'number' = 'text';
  @Input() autocomplete?: string;
  @Input() shouldFocus = false;
  @Input() blur = false;
  @Input() clearInput: boolean | undefined;
  @Input() disableAutocorrect: boolean | undefined;

  errorTypes = ['required', 'email', 'number', 'username', 'password'];
  @Output() clearButtonClicked = new EventEmitter<void>();
  focused = signal(false);

  @ViewChild('input') input: ElementRef | undefined;

  constructor(injector: Injector) {
    super(injector);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shouldFocus'] && !changes['shouldFocus'].firstChange && changes['shouldFocus'].currentValue === true) {
      this.input?.nativeElement.focus();
    }
    if (changes['blur'] && !changes['blur'].firstChange && changes['blur'].currentValue === true) {
      this.input?.nativeElement.blur();
    }

    if (changes['clearInput'] && !changes['clearInput'].firstChange && changes['clearInput'].currentValue) {
      if (changes['clearInput'].currentValue === true) {
        this.onClearButtonClick();
      }
    }
  }

  ngAfterViewInit() {
    if (this.shouldFocus) {
      this.input?.nativeElement.focus();
    }

    if (this.blur) {
      this.input?.nativeElement.blur();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  onClearButtonClick() {
    this.control?.patchValue('');
    this.clearButtonClicked.emit();
  }
}
