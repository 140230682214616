import { AsyncGameEndReason, AsyncGameStateServer, PlayerNumber } from '@kiq/shared/enums';
import { AsyncGameRoundCategory } from './async-game-round-category';
import { AsyncGameRound } from './async-game-round';

export type AsyncMultiplayerGame = {
  gameId: string,
  challengerUserId: string,
  playerIds: Map<PlayerNumber, string>,
  numberOfRounds: number,
  categories: Array<AsyncGameRoundCategory>,

  playerScores: Map<PlayerNumber, number>;
  gamePoints?: Map<PlayerNumber, number>;
  currentRound?: AsyncGameRound,
  playedRounds: Array<AsyncGameRound>,

  gameStatus: AsyncGameStateServer,
  gameEnded: boolean,
  gameEndReason?: AsyncGameEndReason,
  gameEndTimestamp?: Date,
  gameWinner?: PlayerNumber,
  gameTimeoutTimestamp?: Date,
  isRunningOrSelfChallengeRequestedGame: boolean
};
