import { FootballPlayer } from '@kiq/shared/interfaces';
import { GameTopscorerQuestionOption } from './game-topscorer-question-option';
import { AsyncQuestion } from './async-question';

export type AsyncDuellAnswer = {
  questionTimeoutReached: boolean,
  answerIsCorrect?: boolean,
  ticTacToe?: FootballPlayer,
  topscorer?: GameTopscorerQuestionOption,
  question?: AsyncQuestion
}
