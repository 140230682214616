import { OnlineStatisticsActions } from './online-statistics.actions';
import {
  selectAllUserGameStatisticOpponentOverviews,
  selectOtherUser,
  selectUserGameStatisticOpponentDetails,
  selectUserGameStatisticOpponentOverview,
  selectUserGameStatisticOverview,
} from './online-statistics.reducer';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasicUserView } from '@kiq/shared/classes';

@Injectable()
export class OnlineStatisticsFacade {
  private store = inject(Store);

  ownOverallOnlineStatistics$ = this.store.select(selectUserGameStatisticOverview);
  allUserGameStatisticOpponentOverviews$ = this.store.select(selectAllUserGameStatisticOpponentOverviews);
  onlineStatisticsVersusOneUser$ = this.store.select(selectUserGameStatisticOpponentDetails);
  otherUser$ = this.store.select(selectOtherUser);
  otherUsersOverallOnlineStatistics$ = this.store.select(selectUserGameStatisticOpponentOverview);

  getOwnOverallOnlineStatistics() {
    this.store.dispatch(OnlineStatisticsActions.getOwnUserGameStatisticOverviewStart());
  }

  getOtherUsersOverallOnlineStatistics(otherUser: BasicUserView) {
    this.store.dispatch(OnlineStatisticsActions.getOtherUserGameStatisticOverviewStart({ otherUser }));
  }

  getAllOnlineStatistics() {
    this.store.dispatch(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsStart());
  }

  getStatisticsVersusUser(otherUser: BasicUserView) {
    this.store.dispatch(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsStart({ otherUser }));
  }

  getOtherUser(userName: string) {
    this.store.dispatch(OnlineStatisticsActions.getOtherUserStart({ userName }));
  }
}
