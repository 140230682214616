import { Authentication } from './authentication.interface';
import { from, of, skip, switchMap, take } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { FirebaseService, UserFacade } from '@kiq/client/data-access/user';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class AppAuthenticationService implements Authentication {
  private readonly nativeAppService = inject(NativeAppsService);
  private readonly userFacade = inject(UserFacade);
  private readonly actions = inject(Actions);
  private readonly firebaseService = inject(FirebaseService);

  readonly authChanges$ = this.firebaseService.authChanges$;
  readonly token$ = this.firebaseService.firebaseUser$.pipe(
    switchMap((user) => (user ? from(user.getIdToken()) : of(null))),
  );

  constructor() {
    this.actions.pipe(ofType(this.userFacade.getBackendUserFail)).subscribe(() => this.logout());
  }

  login(): void {
    this.nativeAppService.openLoginModal();
  }

  logout(): void {
    this.userFacade.logout();
    this.authChanges$.pipe(skip(1), take(1)).subscribe((user) => this.nativeAppService.logout());
  }

  register(): void {
    this.nativeAppService.openRegisterModal();
  }

  signInWithCustomToken(token: string) {
    this.firebaseService
      .signInWithCustomToken(token)
      .then((userCredentials) => this.userFacade.getOrCreateBackendUserAfterLoginWithCustomAuthToken(userCredentials))
      .catch((error) => {
        console.error('Login mit bestehendem Custom Token fehlgeschlagen:', error);
        this.logout();
      });
  }
}
