import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userFeature } from './+state/user.reducer';
import { UserEffects } from './+state/user.effects';
import { HttpClientModule } from '@angular/common/http';
import { UserBackendService } from './service/user-backend.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/client/util/translation';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    StoreModule.forFeature(userFeature),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [
    UserBackendService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'userDataAccess',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class ClientDataAccessUserModule {}
