import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { BasicUserView, UserOwnerView } from '@kiq/shared/classes';
import { Observable } from 'rxjs';
import { UserCreateRequest } from '@kiq/shared/interfaces';
import { DeviceToken } from '@kiq/shared/types';

@Injectable()
export class UserBackendService {
  private httpClient = inject(HttpClient);

  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1/user`;

  private static getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  getUser$(): Observable<UserOwnerView> {
    return this.httpClient.get<UserOwnerView>(this.BACKEND_URL);
  }

  createUser$(createUserDto: UserCreateRequest): Observable<UserOwnerView> {
    return this.httpClient.post<UserOwnerView>(this.BACKEND_URL, createUserDto);
  }

  updateUser$(userOwnerView: UserOwnerView): Observable<UserOwnerView> {
    return this.httpClient.put<UserOwnerView>(this.BACKEND_URL, userOwnerView);
  }

  deleteUser$(): Observable<void> {
    return this.httpClient.delete<void>(this.BACKEND_URL);
  }

  resetPassword$(email: string) {
    const headers = UserBackendService.getHeader();
    const url = `${this.BACKEND_URL}/sendResetPasswordMessage`;
    const params = new HttpParams().append('email', email);
    return this.httpClient.get(url, { ...headers, params });
  }

  checkUserEmailVerificationStatus$(email: string) {
    const headers = UserBackendService.getHeader();
    const url = `${this.BACKEND_URL}/checkUserEmailVerificationStatus`;
    const params = new HttpParams().set('email', email);
    return this.httpClient.get(url, { ...headers, params });
  }

  sendUserEmailVerificationMessage$() {
    const headers = UserBackendService.getHeader();
    const url = `${this.BACKEND_URL}/sendUserEmailVerificationMessage`;
    return this.httpClient.get(url, headers);
  }

  updateImage$(formData: FormData): Observable<string> {
    const url = `${this.BACKEND_URL}/profileImage`;
    return this.httpClient.post<string>(url, formData);
  }

  deleteImage$(): Observable<void> {
    const url = `${this.BACKEND_URL}/profileImage`;
    return this.httpClient.delete<void>(url);
  }

  getUserWithId$(userId: string): Observable<BasicUserView> {
    const headers = UserBackendService.getHeader();
    const url = `${this.BACKEND_URL}/${userId}`;
    return this.httpClient.get<BasicUserView>(url, headers);
  }

  getUserName$(username: string, token: string): Observable<BasicUserView> {
    const headers = UserBackendService.getHeader(token);
    const url = `${this.BACKEND_URL}/username/${username}`;
    return this.httpClient.get<BasicUserView>(url, headers);
  }

  setNewUserInvitationLink$(): Observable<UserOwnerView> {
    const headers = UserBackendService.getHeader();
    const url = `${this.BACKEND_URL}/gameInvitationLinkId`;
    return this.httpClient.put<UserOwnerView>(url, headers);
  }

  setUserDeviceToken$(deviceToken: DeviceToken): Observable<DeviceToken> {
    const headers = UserBackendService.getHeader();
    const url = `${this.BACKEND_URL}/deviceToken`;
    return this.httpClient.put<DeviceToken>(url, deviceToken, headers);
  }

  deleteUserDeviceToken$(appInstallId: string): Observable<void> {
    const headers = UserBackendService.getHeader();
    const url = `${this.BACKEND_URL}/deviceToken/${appInstallId}`;
    return this.httpClient.delete<void>(url, headers);
  }
}
