import { Injectable } from '@angular/core';
import { NotificationInterface } from './notification.interface';

@Injectable()
export class NotificationService implements NotificationInterface {
  setNotificationConfig(appInstallId?: string, fcmToken?: string) {
    console.log('Browser set Notification config');
  }

  removeDeviceTokenForAppInstallId(appInstallId?: string) {
    console.log('Browser set InstallIdAfterLogout and remove notification settings');
  }
}
