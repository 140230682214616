import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TranslationActions = createActionGroup({
  source: 'Translation',
  events: {
    'Set language settings start': props<{ languageSettings: string | null }>(),
    'Set language settings success': props<{ languageSettings: string | null }>(),
    'Set language settings fail': emptyProps(),

    'retrieve language settings start': emptyProps(),
    'retrieve language settings success': props<{ languageSettings: string }>(),
    'retrieve language settings fail': emptyProps(),
  },
});
