import { GameDifficultyLevel, TrophyStatus, TrophyType } from '@kiq/shared/enums';

export type Trophy = {
  type: TrophyType;
  title: string;
  description: string;
  earnedAt?: Date;
  status?: TrophyStatus;
  isUpgradable?: boolean;
  currentValue?: number;
  nextUpgradeWithValue?: number;
  titleOnTrophy?: string;
  subTitleOnTrophy?: string;
  receivedUpdates?: Array<Trophy>;
  difficulty?: GameDifficultyLevel;

  //only for streak trophies
  currentStreak?: number;
  lastDateForCurrentStreak?: Date;
};
