import { isDevMode } from '@angular/core';
import { provideTransloco } from '@jsverse/transloco';
import { AvailableLanguages } from '../enum/available-languages';
import { TranslocoHttpLoader } from '../transloco-loader/transloco-http-loader';
import { AVAILABLE_LANGS } from '../transloco-loader/scope-loader';

export const TranslocoConfig = () =>
  provideTransloco({
    config: {
      reRenderOnLangChange: true,
      availableLangs: AVAILABLE_LANGS,
      fallbackLang: AvailableLanguages.DE,
      missingHandler: {
        logMissingKey: true,
      },
      prodMode: !isDevMode(),
    },
    loader: TranslocoHttpLoader,
  });
