import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input/text-input.component';
import { BaseInputComponent } from '../base-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUiIconModule } from 'shared/ui/icon';
import { TranslocoDirective } from '@jsverse/transloco';
import { SwitchInputComponent } from './switch-input/switch-input.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedUiIconModule, TranslocoDirective, SwitchInputComponent],
  declarations: [TextInputComponent, BaseInputComponent],
  exports: [TextInputComponent, BaseInputComponent, SwitchInputComponent],
})
export class SharedUiInputsModule {}
