export enum FootballPlayerPositionDetail {
  //defender
  CenterBack = 'centerBack',
  RightBack = 'rightBack',
  LeftBack = 'leftBack',
  Sweeper = 'sweeper',

  //midfielder
  DefensiveMidfied = 'defensiveMidfied',
  AttackingMidfied = 'attackingMidfied',
  CentralMidfied = 'centralMidfied',
  LeftMidfield = 'leftMidfield',
  RightMidfield = 'rightMidfield',

  //attacker
  CenterForward = 'centerForward',
  LeftWing = 'leftWing',
  RightWing = 'rightWing',
  SecondaryStriker = 'secondaryStriker',
}
