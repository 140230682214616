import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TRANSLOCO_SCOPE, TranslocoDirective } from '@jsverse/transloco';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../../base-input.component';
import { scopeLoader } from '@kiq/client/util/translation';

@Component({
  selector: 'kiq-input-button',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './input-button.component.html',
  styleUrl: './input-button.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputButtonComponent),
      multi: true,
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'inputs',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class InputButtonComponent extends BaseInputComponent implements OnInit {
  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }

  @Input() placeholder: string | undefined;

  readonly errorTypes = ['required'];
}
