import { inject, Injectable } from '@angular/core';
import {
  applyActionCode,
  Auth, authState,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  user,
  verifyPasswordResetCode,
  signInWithCustomToken
} from '@angular/fire/auth';
import { EmailAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { ActionCodeInfo } from '@firebase/auth';
import { UserBackendService } from './user-backend.service';

@Injectable({ providedIn: 'root' })
export class FirebaseService {
  private readonly userBackendService = inject(UserBackendService);
  private readonly  auth = inject(Auth);
  readonly firebaseUser$ = user(this.auth);
  readonly authChanges$ = authState(this.auth);

  signUpWithEmail$(email: string, password: string) {
    return from(createUserWithEmailAndPassword(this.auth, email, password));
  }

  //todo: deleteFirebaseUser$(user: User) {}

  signUpWithGoogle$() {
    const provider = new GoogleAuthProvider();
    return from(signInWithPopup(this.auth, provider));
  }

  signUpWithApple$() {
    const provider = new OAuthProvider('apple.com');
    return from(signInWithPopup(this.auth, provider));
  }

  loginWithEmail$(email: string, password: string) {
    return from(signInWithEmailAndPassword(this.auth, email, password));
  }

  verifyEmail$(code: string): Observable<any> {
    let actionCode: ActionCodeInfo;
    return from(checkActionCode(this.auth, code)).pipe(
      switchMap((actionCodeInfo) => {
        actionCode = actionCodeInfo;
        return from(applyActionCode(this.auth, code));
      }),
      switchMap(() => {
        if (actionCode?.data?.email) {
          return this.userBackendService.checkUserEmailVerificationStatus$(actionCode.data.email);
        }
        return throwError('error');
      }),
      catchError((error) => {
        const errorMessage = 'error while verifying';
        //TODO: add Snackbar;
        return throwError(() => {
          return errorMessage;
        });
      }),
    );
  }

  verifyAndConfirmPassword$(code: string, newPassword: string): Observable<void> {
    // Checks the password reset code sent to the user by email or other out-of-band mechanism.
    // Returns the user's email address if valid.
    return from(verifyPasswordResetCode(this.auth, code)).pipe(
      switchMap((email) => {
        // Completes the password reset process, given a confirmation code and new password.
        return from(confirmPasswordReset(this.auth, code, newPassword));
      }),
    );
  }

  reauthenticateWithCredential$(password: string) {
    const user = this.auth.currentUser;
    if (!user || !user.email) {
      return new Observable((observer) => {
        observer.error('no user available');
        observer.complete();
      });
    }
    const credential = EmailAuthProvider.credential(user.email, password);
    return from(reauthenticateWithCredential(user, credential));
  }

  updatePassword$(password: string) {
    const user = this.auth.currentUser;
    if (!user) {
      return new Observable((observer) => {
        observer.error('no user available');
        observer.complete();
      });
    }
    return from(updatePassword(user, password));
  }

  logoutFirebaseUser$() {
    return from(signOut(this.auth));
  }

  signInWithCustomToken(token: string) {
    return signInWithCustomToken(this.auth, token);
  }
}
