import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { TranslationActions } from './translation.actions';

export interface TranslationState {
  loading: boolean;
  languageSettings: string | null;
}

export const initialTranslationState: TranslationState = {
  loading: false,
  languageSettings: null,
};
export const translationFeature = createFeature({
  name: 'translation',
  reducer: createReducer(
    initialTranslationState,

    immerOn(TranslationActions.setLanguageSettingsStart, (state) => {
      state.loading = true;
    }),
    immerOn(TranslationActions.setLanguageSettingsSuccess, (state, { languageSettings }) => {
      state.languageSettings = languageSettings;
      state.loading = false;
    }),
    immerOn(TranslationActions.setLanguageSettingsFail, (state) => {
      state.loading = false;
    }),

    immerOn(TranslationActions.retrieveLanguageSettingsStart, (state) => {
      state.loading = true;
    }),
    immerOn(TranslationActions.retrieveLanguageSettingsSuccess, (state, { languageSettings }) => {
      state.languageSettings = languageSettings;
      state.loading = false;
    }),
    immerOn(TranslationActions.retrieveLanguageSettingsFail, (state) => {
      state.loading = false;
    }),
  ),
});
export const {
  name, // feature name
  reducer, // feature reducer
  selectLoading,
  selectLanguageSettings,
} = translationFeature;
