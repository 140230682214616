import { inject, Injectable } from '@angular/core';
import { NotificationInterface } from './notification.interface';
import { UserFacade } from '@kiq/client/data-access/user';
import { Observable, of } from 'rxjs';

@Injectable()
export class AppNotificationService implements NotificationInterface {
  private readonly userFacade = inject(UserFacade);

  setNotificationConfig(appInstallId: string, fcmToken: string) {
    this.userFacade.setUserDeviceToken(appInstallId, fcmToken);
  }

  removeDeviceTokenForAppInstallId(appInstallId: string) {
    this.userFacade.deleteUserDeviceToken(appInstallId);
  }

  setNotificationsModalTokenToLocalStorage(notificationsModalShown: boolean) {
    if (notificationsModalShown) {
      localStorage.setItem('notificationsModalToken', JSON.stringify(notificationsModalShown));
    }
  }

  getNotificationsModalTokenFromLocalStorage(): Observable<boolean | undefined> {
    const notificationsModalShown = localStorage.getItem('notificationsModalToken');

    const parsedNotificationsModalShown = JSON.parse(notificationsModalShown || 'false');
    return of(parsedNotificationsModalShown);
  }
}
