import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Injector,
  Input,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { wordpressRoutes } from '@kiq/client/util/routing';
import { FormBuilder, Validators } from '@angular/forms';
import { UserFacade } from '@kiq/client/data-access/user';
import { Router } from '@angular/router';
import { UsernameValidators } from '@kiq/shared/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FootballTeam, InGameModalConfig, RedirectUrlWithParams } from '@kiq/shared/types';
import { InGameModalService, MODAL_CONFIG, MODAL_REF, ModalService } from '@kiq/shared/util/modal';
import { Actions, ofType } from '@ngrx/effects';
import { ClubSearchComponent } from 'client/feature/club-search';
import { ConfigFacade, CustomTitleStrategyService } from '@kiq/client/data-access/config';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/client/util/translation';
import { AUTH_SERVICE } from '@kiq/shared/data-access/authentication';
import { take } from 'rxjs';

@Component({
  selector: 'kiq-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'authentication',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class RegisterComponent implements OnInit {
  @Input() redirectUrlWithParams?: RedirectUrlWithParams;

  private readonly router = inject(Router);
  private readonly fb = inject(FormBuilder);
  private readonly userFacade = inject(UserFacade);
  private readonly destroyRef = inject(DestroyRef);
  private readonly modalRef = inject(MODAL_REF);
  private readonly modalConfig$ = inject(MODAL_CONFIG);
  private readonly modalService = inject(ModalService);
  private readonly actions = inject(Actions);
  private readonly titleService = inject(CustomTitleStrategyService);
  private readonly configFacade = inject(ConfigFacade);
  private readonly nativeAppsService = inject(NativeAppsService);
  private readonly authenticationService = inject(AUTH_SERVICE);
  private readonly cdr = inject(ChangeDetectorRef);

  private readonly inGameModalService: InGameModalService | null;
  protected readonly WP_ROUTES = wordpressRoutes;
  protected readonly GOOGLE_LOGO = 'assets/icons/google-logo.svg';
  loading$ = this.userFacade.loading$;

  @ViewChild('userName') userNameInput?: ElementRef;

  focus = signal(0);
  focusOut = signal(false);
  favouriteClub: WritableSignal<FootballTeam | null> = signal(null);

  registerForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    username: [
      '',
      [Validators.required, UsernameValidators.username, Validators.minLength(3), Validators.maxLength(32)],
    ],
    favouriteClub: ['', [Validators.required]],
    privacyPolicy: [false, Validators.requiredTrue],
  });

  constructor(injector: Injector) {
    this.inGameModalService = injector.get(InGameModalService, null);
  }

  ngOnInit() {
    this.titleService.setTitle('Registrieren Dialog');

    this.actions
      .pipe(ofType(this.userFacade.createNewBackendUserSuccess), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.closeDialog();
      });
  }

  register() {
    const { email, password, username, favouriteClub } = this.registerForm.value;

    if (!this.registerForm.valid || !email || !password || !username || !favouriteClub || !this.favouriteClub()) {
      return;
    }

    if (this.favouriteClub() !== null) {
      this.userFacade.createNewUser(email, password, username, this.favouriteClub()!, this.redirectUrlWithParams);
    }
  }

  closeDialog() {
    this.modalRef.close();
  }

  registerWithGoogle() {
    this.userFacade.createUserWithGoogle();
  }

  openLogin() {
    this.authenticationService.login();
  }

  openClubSearch() {
    if (this.inGameModalService) {
      const inGameModalConfig: InGameModalConfig = {
        component: ClubSearchComponent,
        isFullModalHeight: true,
      };
      const modalRef = this.inGameModalService.openInGameModal<FootballTeam, unknown>(inGameModalConfig);

      modalRef.afterClosed$.pipe(take(1)).subscribe((club) => {
        this.registerForm.controls.favouriteClub.setValue(club?.name ?? null);

        if (club) {
          this.favouriteClub.set(club);
        }

        this.cdr.detectChanges();
      });
    }
  }

  openUrl(url: string) {
    if (this.configFacade.nativeAppType()) {
      this.nativeAppsService.goToUrl(url);
    } else {
      window.open(url);
    }
  }
}
