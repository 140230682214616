import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectDeviceToken,
  selectFcmToken,
  selectIsLoggedIn,
  selectLandingAlreadyVisited,
  selectLoading,
  selectProfileNotification,
  selectUser,
  UserState,
  userVm,
} from './user.reducer';
import { UserActions } from './user.actions';
import { FootballTeam, RedirectUrlWithParams } from '@kiq/shared/types';
import { UserOwnerView } from '@kiq/shared/classes';
import {
  BackendUserUpdateType,
  NotificationSettings,
  UserFootballPlayerPosition,
  UserFootballRole,
} from '@kiq/shared/enums';

@Injectable({ providedIn: 'root' })
export class UserFacade {
  private store: Store<UserState> = inject(Store);

  readonly loading$ = this.store.select(selectLoading);
  readonly user$ = this.store.select(selectUser);
  readonly isLoggedIn$ = this.store.select(selectIsLoggedIn);
  readonly profileNotification$ = this.store.select(selectProfileNotification);
  readonly landingAlreadyVisited$ = this.store.select(selectLandingAlreadyVisited);
  readonly vm$ = this.store.select(userVm);
  readonly deviceToken$ = this.store.select(selectDeviceToken);
  readonly fcmToken$ = this.store.select(selectFcmToken);

  readonly getBackendUserSuccess = UserActions.getBackendUserSuccess;
  readonly createNewBackendUserSuccess = UserActions.createNewBackendUserSuccess;
  readonly getBackendUserFail = UserActions.getBackendUserFailure;

  createNewUser(
    email: string,
    password: string,
    username: string,
    favouriteClub: FootballTeam,
    redirectUrlWithParams?: RedirectUrlWithParams,
  ) {
    this.store.dispatch(
      UserActions.createNewFirebaseUserStart({
        email,
        password,
        username,
        favouriteClub,
        redirectUrlWithParams,
      }),
    );
  }

  login(email: string, password: string, redirectUrlWithParams?: RedirectUrlWithParams) {
    this.store.dispatch(UserActions.loginStart({ email, password, redirectUrlWithParams }));
  }

  logout() {
    this.store.dispatch(UserActions.logoutFirebaseUserStart());
  }

  getBackendUser() {
    this.store.dispatch(UserActions.getBackendUser({}));
  }

  loginWithGoogle() {
    this.store.dispatch(UserActions.loginUserWithGoogleStart());
  }

  loginWithApple() {
    this.store.dispatch(UserActions.loginUserWithAppleStart());
  }

  createUserWithGoogle() {
    this.store.dispatch(UserActions.createNewUserWithGoogleStart());
  }

  createUserWithApple() {
    this.store.dispatch(UserActions.createNewUserWithAppleStart());
  }

  uploadeProfileImage(file: File) {
    this.store.dispatch(UserActions.uploadProfileImageStart({ file }));
  }

  resetPassword(email: string) {
    this.store.dispatch(UserActions.resetPasswordStart({ email }));
  }

  verifyAndConfirmPasswordReset(oobCode: string, password: string) {
    this.store.dispatch(UserActions.verifyAndConfirmPasswordResetStart({ oobCode, password }));
  }

  updateUser(user: UserOwnerView, updateType: BackendUserUpdateType) {
    this.store.dispatch(UserActions.updateBackendUserStart({ user, updateType }));
  }

  updateUsername(username: string) {
    this.store.dispatch(UserActions.updateUsername({ username }));
  }

  updateFirstname(firstname: string) {
    this.store.dispatch(UserActions.updateFirstname({ firstname }));
  }

  updateLastname(lastname: string) {
    this.store.dispatch(UserActions.updateLastname({ lastname }));
  }

  updateUserFootballRole(userFootballRole: UserFootballRole) {
    this.store.dispatch(UserActions.updateUserFootballRole({ userFootballRole }));
  }

  updateUserFootballPlayerPosition(userFootballPlayerPosition: UserFootballPlayerPosition) {
    this.store.dispatch(UserActions.updateUserFootballPlayerPosition({ userFootballPlayerPosition }));
  }

  updateFavouriteClub(favClubId: FootballTeam) {
    this.store.dispatch(UserActions.updateFavouriteClub({ favClubId }));
  }

  updateUserMail(email: string) {
    this.store.dispatch(UserActions.updateEmailAddress({ email }));
  }

  updateYearOfBirth(yearOfBirth: number) {
    this.store.dispatch(UserActions.updateUserYearOfBirth({ yearOfBirth }));
  }

  updateUserLanguage(language: string) {
    this.store.dispatch(UserActions.updateUserLanguage({ language }));
  }

  updateUserNotificationSettings(notificationSettings: Array<NotificationSettings>) {
    this.store.dispatch(UserActions.updateUserNotificationSettings({ notificationSettings }));
  }

  updatePassword(oldPassword: string, newPassword: string) {
    this.store.dispatch(UserActions.updatePasswordStart({ oldPassword, newPassword }));
  }

  deleteAccount() {
    this.store.dispatch(UserActions.deleteAccountStart());
  }

  deleteProfileImage() {
    this.store.dispatch(UserActions.deleteProfileImageStart());
  }

  setProfileNotification(profileNotification: boolean) {
    this.store.dispatch(UserActions.setProfileNotification({ profileNotification }));
  }

  getProfileNotification() {
    this.store.dispatch(UserActions.getProfileNotification());
  }

  sendEmailVerification() {
    this.store.dispatch(UserActions.sendEmailVerification());
  }

  setNewUserInvitationLink() {
    this.store.dispatch(UserActions.setNewUserInvitationLink());
  }

  setUserDeviceToken(appInstallId: string, fcmToken: string) {
    this.store.dispatch(UserActions.setUserDeviceToken({ appInstallId, fcmToken }));
  }

  deleteUserDeviceToken(appInstallId: string) {
    this.store.dispatch(UserActions.deleteUserDeviceToken({ appInstallId }));
  }

  setLandingAlreadyVisitedToken(alreadyVisited: boolean) {
    this.store.dispatch(UserActions.setLandingAlreadyVisitedToken({ alreadyVisited }));
  }

  retrieveLandingAlreadyVisitedToken() {
    this.store.dispatch(UserActions.retrieveLandingAlreadyVisitedToken());
  }
}
