import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import {
  UserGameStatisticOpponentDetail,
  UserGameStatisticOpponentOverview,
  UserGameStatisticOverview,
} from '@kiq/shared/types';
import { Observable } from 'rxjs';
import { GameResult, UserStatisticGameType } from '@kiq/shared/enums';

@Injectable()
export class OnlineStatisticsService {
  private readonly http = inject(HttpClient);

  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1/user`;
  private readonly BACKEND_URL_STATISTIC = '/gameStatistics';
  private readonly BACKEND_URL_STATISTIC_OPPONENT = '/opponent';

  DUMMY_OVERALL_STATISTIC: UserGameStatisticOverview = {totalMatches: 118, wins: 78, draws: 10, losses: 30};
  DUMMY_OVERALL_STATISTIC_OPPONENT: UserGameStatisticOpponentOverview = {user: {id: '124', username: 'Glockenhorst'}, totalMatches: 118, wins: 78, draws: 10, losses: 30};

  DUMMY_OPPONENTS: Array<UserGameStatisticOpponentOverview> = [
    this.DUMMY_OVERALL_STATISTIC_OPPONENT,
    { user: {id: '124', username: 'Leo'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'ShinjiKagawa'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'Poppy'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
    { user: {id: '124', username: 'DerSechser'}, totalMatches: 49, wins: 39, draws: 5, losses: 5 },
  ];

  DUMMY_ONLINE_STATISTICS_VERSUS_USER: Array<UserGameStatisticOpponentDetail> = [
    {
      type: UserStatisticGameType.TTT,
      overview: {
        totalMatches: 10,
        wins: 5,
        draws: 3,
        losses: 2,
      },
      recentGames: [GameResult.WIN, GameResult.WIN, GameResult.LOSS, GameResult.DRAW, GameResult.WIN],
    },
    {
      type: UserStatisticGameType.TTT_NATIONS,
      overview: {
        totalMatches: 100,
        wins: 30,
        draws: 50,
        losses: 20,
      },
      recentGames: [GameResult.WIN, GameResult.WIN, GameResult.LOSS, GameResult.DRAW, GameResult.WIN],
    },
    {
      type: UserStatisticGameType.TTT_TRANSFER,
      overview: {
        totalMatches: 100,
        wins: 90,
        draws: 10,
        losses: 0,
      },
      recentGames: [GameResult.WIN, GameResult.WIN, GameResult.LOSS, GameResult.DRAW, GameResult.WIN],
    },
  ];

  private static getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }


  // own user
  //list of opponents
  getAllUserGameStatisticOpponentOverviews(): Observable<Array<UserGameStatisticOpponentOverview>> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url = this.BACKEND_URL + this.BACKEND_URL_STATISTIC;
    return this.http.get<Array<UserGameStatisticOpponentOverview>>(url, httpOptions);
  }

  //overview
  getOwnUserStatisticOverview(userId: string): Observable<UserGameStatisticOverview> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url = this.BACKEND_URL + '/' + userId + this.BACKEND_URL_STATISTIC;
    return this.http.get<UserGameStatisticOverview>(url, httpOptions);
  }

  //for other users
  //other users overview
  getUserGameStatisticOpponentOverview(otherUsersId: string): Observable<UserGameStatisticOverview> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url = this.BACKEND_URL + '/' + otherUsersId + this.BACKEND_URL_STATISTIC;
    return this.http.get<UserGameStatisticOpponentOverview>(url, httpOptions);
  }

  //details of opponent against own user
  getAllUserGameStatisticOpponentDetails(otherUsersId: string): Observable<Array<UserGameStatisticOpponentDetail>> {
    const httpOptions = OnlineStatisticsService.getHeader();
    const url = this.BACKEND_URL + this.BACKEND_URL_STATISTIC + this.BACKEND_URL_STATISTIC_OPPONENT + '/' + otherUsersId;
    return this.http.get<Array<UserGameStatisticOpponentDetail>>(url, httpOptions);
  }
}
