import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, UserFacade } from '@kiq/client/data-access/user';
import { clientRoutes } from '@kiq/client/util/routing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidators } from '@kiq/shared/validators';
import { CustomTitleStrategyService } from '@kiq/client/data-access/config';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/client/util/translation';

@Component({
  selector: 'kiq-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'authentication',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class VerifyComponent implements OnInit, OnDestroy {
  fb = inject(FormBuilder);
  userFacade = inject(UserFacade);
  titleService = inject(CustomTitleStrategyService);
  protected readonly clientRoutes = clientRoutes;
  readonly MAIL_ICON = './assets/icons/mail-check.svg';

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authService = inject(AuthService);

  public authError = null;
  public mode = this.route.snapshot.queryParams['mode'];
  public oobCode = this.route.snapshot.queryParams['oobCode'];
  resetPwForm: FormGroup;

  auth$: Subscription | undefined;

  constructor() {
    this.resetPwForm = this.fb.group(
      {
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      { validators: [PasswordValidators.passwordMatch] },
    );
  }

  ngOnInit() {
    if (!this.mode || !this.oobCode) {
      this.router.navigate(['']);
    }

    if (this.mode === 'verifyEmail' || this.mode === 'verifyAndChangeEmail') {
      this.titleService.setTitle('E-Mail Verifizierung Dialog');
      this.auth$ = this.authService.verifyEmail$(this.oobCode).subscribe({
        next: () => {
          console.log(this.oobCode);
        },
        error: (error: null) => {
          this.authError = error;
        },
      });
    }
    if (this.mode === 'resetPassword') {
      this.titleService.setTitle('Passwort Reset Bestätigung Dialog');
    }
  }

  ngOnDestroy() {
    this.authError = null;
    this.auth$?.unsubscribe();
  }

  submitNewPassword() {
    const newPassword = this.resetPwForm.controls['newPassword'].value;
    if (this.mode === 'resetPassword' && newPassword != null) {
      this.userFacade.verifyAndConfirmPasswordReset(this.oobCode, newPassword);
    }
  }
}
