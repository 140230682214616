import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserCreateRequest } from '@kiq/shared/interfaces';
import { UserCredential } from '@angular/fire/auth';
import { UserOwnerView } from '@kiq/shared/classes';
import { DeviceToken, FootballTeam, RedirectUrlWithParams } from '@kiq/shared/types';
import {
  AllowInviteFromUser,
  BackendUserUpdateType,
  NotificationSettings,
  UserFootballPlayerPosition,
  UserFootballRole,
} from '@kiq/shared/enums';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'create new firebase user start': props<{
      email: string;
      password: string;
      username: string;
      favouriteClub: FootballTeam;
      redirectUrlWithParams?: RedirectUrlWithParams;
    }>(),
    'create new firebase user success': props<{
      user: UserCredential;
      username: string;
      favouriteClub?: FootballTeam;
      redirectUrlWithParams?: RedirectUrlWithParams;
    }>(),
    'create new firebase user failure': props<{
      error: string;
    }>(),

    'create new user with google start': props<{ redirectUrlWithParams?: RedirectUrlWithParams }>(),
    'create new user with apple start': props<{ redirectUrlWithParams?: RedirectUrlWithParams }>(),

    'login user with google start': props<{ redirectUrlWithParams?: RedirectUrlWithParams }>(),
    'login user with apple start': props<{ redirectUrlWithParams?: RedirectUrlWithParams }>(),

    'login user with popup success': props<{
      userCredential: UserCredential;
      redirectUrlWithParams?: RedirectUrlWithParams;
    }>(),
    'login user with popup fail': props<{
      error: string;
    }>(),

    'delete firebase user start': props<{
      error: string;
    }>(),
    'delete firebase user success': props<{
      error: string;
    }>(),
    'delete firebase user fail': props<{
      error: string;
    }>(),

    'create new backend user': props<{
      user: UserCreateRequest;
      redirectUrlWithParams?: RedirectUrlWithParams;
    }>(),
    'create new backend user success': props<{
      user: UserOwnerView;
    }>(),
    'create new backend user failure': props<{
      error: string;
    }>(),

    'login start': props<{
      email: string;
      password: string;
      redirectUrlWithParams?: RedirectUrlWithParams;
    }>(),
    'login success': props<{ redirectUrlWithParams?: RedirectUrlWithParams }>(),
    'login failure': props<{
      error: string;
    }>(),

    'get backend user after popup login': props<{
      userCredential: UserCredential;
      redirectUrlWithParams?: RedirectUrlWithParams;
    }>(),

    'get backend user': props<{ redirectUrlWithParams?: RedirectUrlWithParams }>(),
    'get backend user success': props<{
      user: UserOwnerView;
    }>(),
    'get backend user failure': props<{
      error: string;
    }>(),

    'get backend user failure after popup login': props<{
      userCredential: UserCredential;
    }>(),

    'get backend user failure after popup login fail': props<{
      error: string;
    }>(),

    'logout firebase user start': emptyProps(),
    'logout firebase user success': emptyProps(),
    'logout firebase user fail': props<{
      error: string;
    }>(),

    'update profile image start': props<{ imageUrl: string }>(),
    'update profile image success': props<{ imageUrl: string }>(),
    'update profile image fail': props<{ error: string }>(),

    'delete profile image start': emptyProps(),
    'delete profile image success': emptyProps(),
    'delete profile image fail': props<{ error: string }>(),

    'update password start': props<{
      oldPassword: string;
      newPassword: string;
    }>(),
    'update password success': emptyProps(),
    'update password fail': props<{
      error: string;
    }>(),

    'delete account start': emptyProps(),
    'delete account success': emptyProps(),
    'delete account fail': props<{
      error: string;
    }>(),

    'upload profile image start': props<{
      file: File;
    }>(),
    'upload profile image success': props<{
      imageUrl: string;
    }>(),
    'upload profile image fail': props<{
      error: string;
    }>(),

    'reset password start': props<{
      email: string;
    }>(),
    'reset password success': emptyProps(),
    'reset password fail': emptyProps(),

    'verify and confirm password reset start': props<{
      oobCode: string;
      password: string;
    }>(),
    'verify and confirm password reset success': emptyProps(),
    'verify and confirm password reset fail': emptyProps(),

    'reauthenticate user with token start': emptyProps(),
    'reauthenticate user with token success': emptyProps(),
    'reauthenticate user with token fail': props<{
      error: string;
    }>(),

    'set profile notification': props<{ profileNotification: boolean }>(),
    'set profile notification success': emptyProps(),

    'get profile notification': emptyProps(),
    'get profile notification success': props<{ profileNotification: boolean }>(),

    'send email verification': emptyProps(),
    'send email verification success': emptyProps(),
    'send email verification fail': props<{ error: string }>(),

    'set new user invitation link': emptyProps(),
    'set new user invitation link success': props<{ user: UserOwnerView }>(),
    'set new user invitation link fail': props<{ error: string }>(),

    'set user device token': props<{ appInstallId: string; fcmToken: string }>(),
    'set user device token success': props<{ deviceToken: DeviceToken; fcmToken: string }>(),
    'set user device token fail': props<{ error: string }>(),

    'delete user device token': props<{ appInstallId: string }>(),
    'delete user device token success': emptyProps(),
    'delete user device token fail': props<{ error: string }>(),

    'update backend user start': props<{
      user: UserOwnerView;
      updateType: BackendUserUpdateType;
    }>(),
    'update backend user success': props<{
      user: UserOwnerView;
    }>(),
    'update backend user fail': props<{
      error: string;
    }>(),

    'update username': props<{
      username: string;
    }>(),

    'update favourite club': props<{
      favClubId: FootballTeam;
    }>(),

    'update email address': props<{
      email: string;
    }>(),

    'update firstname': props<{
      firstname: string;
    }>(),

    'update lastname': props<{
      lastname: string;
    }>(),

    'update user football role': props<{
      userFootballRole: UserFootballRole;
    }>(),

    'update user football player position': props<{
      userFootballPlayerPosition: UserFootballPlayerPosition;
    }>(),

    'update user year of birth': props<{
      yearOfBirth: number;
    }>(),

    'update user language': props<{
      language: string;
    }>(),

    'update user notification settings': props<{
      notificationSettings: Array<NotificationSettings>;
    }>(),

    'update user challengeability settings': props<{
      allowInviteFromUser: AllowInviteFromUser;
    }>(),

    'set landing already visited token': props<{ alreadyVisited: boolean }>(),
    'set landing already visited token success': emptyProps(),

    'retrieve landing already visited token': emptyProps(),
    'retrieve landing already visited token success': props<{ alreadyVisited: boolean }>(),
  },
});
