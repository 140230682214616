import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { translationFeature } from './+state/translation.reducer';
import { TranslationEffects } from './+state/translation.effects';
import { TranslationFacade } from './+state/translation.facade';
import { TranslationService } from './service/translation.service';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/client/util/translation';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(translationFeature),
    EffectsModule.forFeature([TranslationEffects]),
  ],
  providers: [
    TranslationFacade,
    TranslationService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'translationDataAccess',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class SharedDataAccessTranslationSettingsModule {}
