export enum AsyncServerState {
  PLAYER_1_TURN = 'player1Turn',
  CHALLENGED = 'challenged',
  PLAYER_2_TURN = 'player2Turn',
  TIME_IS_UP = 'timeIsUp',
  PLAYER_1_GAVE_UP = 'player1GaveUp',
  PLAYER_2_GAVE_UP = 'player2GaveUp',
  DRAW = 'draw',
  PLAYER_1_WON = 'player1Won',
  PLAYER_2_WON = 'player2Won',
}
