<ng-container
  *ngrxLet="{ loading: allClubsLoading$, filteredClubs: filteredClubs$, popularClubs: popularClubs$ } as vm">
  <div class="flex flex-col h-full bg-surfaceSecondary relative">
    <div class="px-4 py-2 gap-x-4 flex items-center bg-zinc-950">
      <span class=""><shared-icon class="icon-wrapper" [name]="'magnifying-glass'"></shared-icon></span>
      <kiq-text-input
        class="w-full"
        [shouldFocus]="shouldFocus()"
        [formControl]="clubSearchFormControl"
        [inputType]="'text'"
        [disableAutocorrect]="true"
        [placeholder]="favoriteClubPlaceholder() ?? undefined"
        (keydown)="onKeyDown($event, vm.filteredClubs)"
        (clearButtonClicked)="selectedIndex = undefined"
        type="text" />
      <client-button class="h-12" [buttonType]="'tertiary'" [size]="'medium'" (click)="closeDialog()">
        <p>{{ cancelLabel() }}</p>
      </client-button>
    </div>
    @if (!vm.loading) {
      @if (vm.filteredClubs && vm.filteredClubs.length > 0 && searchLongerThanTwo()) {
        <div class="flex-grow p-4 bg-surfaceSecondary overflow-y-auto text-textPrimary" #listContainer>
          <div
            class="suggestions flex flex-col text-textPrimary"
            *ngIf="vm.filteredClubs && vm.filteredClubs.length > 0">
            <ng-container *ngFor="let suggestion of vm.filteredClubs; let i = index">
              <div
                class="suggestion flex items-center space-between h-12 py-2.5 px-2 cursor-pointer border-b border-grey18 hover:bg-grey20 active:bg-grey18"
                [class.hover]="i === selectedIndex"
                (click)="selectClub(suggestion)">
                <div class="name flex items-center grow gap-2">
                  <h5 class="line-clamp-1">{{ suggestion.name }}</h5>
                </div>
                <div class="select shrink-0">
                  <shared-icon class="icon-wrapper" name="chevron-right"></shared-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      } @else if (searchLongerThanTwo()) {
        <div class="text-textPrimary p-4 text-center" *transloco="let t; prefix: 'clubSearch'">{{
          t("noResults")
        }}</div>
      } @else {
        @if (vm.popularClubs) {
          <div class="text-textSecondary px-[24px] pt-4" *transloco="let t; prefix: 'clubSearch'">
            {{ t("popularClubs") }}</div
          >
          <div class="flex-grow p-4 pt-2 bg-surfaceSecondary overflow-y-auto text-textPrimary">
            <div class="suggestions flex flex-col text-textPrimary">
              <ng-container *ngFor="let popularClub of vm.popularClubs; let i = index">
                <div
                  class="suggestion flex items-center space-between h-12 py-2.5 px-2 cursor-pointer border-b border-grey18 hover:bg-grey20 active:bg-grey18 text-textPrimary"
                  [class.hover]="i === selectedIndex"
                  (click)="selectClub(popularClub)">
                  <div class="name flex items-center grow gap-2">
                    <h5 class="line-clamp-1">{{ popularClub.name }}</h5>
                  </div>
                  <div class="select shrink-0">
                    <shared-icon class="icon-wrapper" name="chevron-right"></shared-icon>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        }
      }
    } @else {
      <ng-template #loading> loading</ng-template>
    }
  </div>
</ng-container>
