import { Component, inject, OnInit } from '@angular/core';
import { TranslationFacade } from 'shared/data-access/translation-settings';

@Component({
  selector: 'kiq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private translationFacade = inject(TranslationFacade);

  ngOnInit() {
    this.translationFacade.getLastLanguageSettingsFromUser();
  }
}
