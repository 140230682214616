<div
  class="flex text-gray-200 items-center border border-grey14 lg:hover:border-white rounded bg-surfaceSecondary px-3 py-3.5 gap-2"
  [ngClass]="{
    'border-white': focused(),
    small: size === TextInputSize.SMALL
  }">
  <span class="prefix" *ngIf="prefixIconName" [class.padding-left]="size === TextInputSize.NORMAL">
    <shared-icon class="icon-wrapper" [name]="prefixIconName" [class.small-icon]="size === TextInputSize.SMALL" />
  </span>

  <input
    class="bg-surfaceSecondary w-full text-white text-base font-normal placeholder:text-neutral-400 placeholder:text-base placeholder:font-normal active:outline-none focus:outline-none"
    #input
    [formControl]="control"
    [autocomplete]="autocomplete"
    [type]="inputType"
    [placeholder]="placeholder"
    [spellcheck]="!disableAutocorrect"
    [attr.autocorrect]="disableAutocorrect ? 'off' : 'on'"
    [attr.autocomplete]="disableAutocorrect ? 'off' : 'on'"
    (focusin)="focused.set(true)"
    (focusout)="focused.set(false)" />
  <span
    class="suffix flex"
    *ngIf="control.dirty && (control.value.length ?? 0) > 0"
    [class.pr-4]="size !== TextInputSize.SMALL">
    <button class="p-0" [disabled]="control.disabled" (click)="onClearButtonClick()" type="button" tabindex="-1">
      <shared-icon class="icon-wrapper" name="close"></shared-icon>
    </button>
  </span>
</div>
<ng-container *transloco="let t; prefix: 'inputs.textInput'">
  <label class="label" *ngIf="(hint?.length ?? 0) > 0">
    <span class="label-text-alt text-white">{{ hint }}</span>
  </label>

  <label
    *ngIf="
      errorTypes.includes('required') &&
      control?.hasError('required') &&
      control?.dirty &&
      control?.touched &&
      !focused()
    ">
    <span class="label-text-alt text-red-600">{{ t("requiredError") }}</span>
  </label>
  <label
    *ngIf="
      errorTypes.includes('email') && control?.hasError('email') && control?.dirty && control?.touched && !focused()
    ">
    <span class="label-text-alt text-red-600">{{ t("mailError") }}</span>
  </label>
  <label
    *ngIf="
      errorTypes.includes('username') &&
      control?.hasError('invalidUsername') &&
      control?.dirty &&
      control?.touched &&
      !focused()
    ">
    <span class="label-text-alt text-red-600">{{ t("usernameError") }}</span>
  </label>
  <label
    *ngIf="
      inputType === 'password' && control?.hasError('minlength') && control?.dirty && control?.touched && !focused()
    ">
    <span class="label-text-alt text-red-600">{{ t("minLengthError") }}</span>
  </label>
  <label
    *ngIf="
      inputType === 'text' &&
      errorTypes.includes('username') &&
      control?.hasError('maxlength') &&
      control?.dirty &&
      control?.touched &&
      !focused()
    ">
    <span class="label-text-alt text-red-600">{{ t("minLengthUserError") }}</span>
  </label>
  <label
    *ngIf="
      inputType === 'text' &&
      errorTypes.includes('username') &&
      control?.hasError('minlength') &&
      control?.dirty &&
      control?.touched &&
      !focused()
    ">
    <span class="label-text-alt text-red-600">{{ t("maxLengthUserError") }}</span>
  </label>
</ng-container>
