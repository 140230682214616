import { AvailableLanguages } from '../enum/available-languages';

export const AVAILABLE_LANGS = Object.values(AvailableLanguages);

export const scopeLoader = (importer: any, root = 'i18n') => {
  return AVAILABLE_LANGS.reduce((acc: any, lang) => {
    acc[lang] = () => importer(lang, root);
    return acc;
  }, {});
};
