import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { UserActions } from './user.actions';
import { UserOwnerView } from '@kiq/shared/classes';
import { DeviceToken } from '@kiq/shared/types';

export interface UserState {
  loading: boolean;
  user: UserOwnerView | null;
  error: string | null;
  profileNotification: boolean;
  deviceToken: DeviceToken | null;
  landingAlreadyVisited: boolean;
  fcmToken: string | null;
}

export const initialUserState: UserState = {
  loading: false,
  user: null,
  error: null,
  profileNotification: true,
  deviceToken: null,
  landingAlreadyVisited: false,
  fcmToken: localStorage.getItem('fcmToken') ?? null,
};

export const userFeature = createFeature({
  name: 'user',

  reducer: createReducer(
    initialUserState,
    immerOn(UserActions.createNewFirebaseUserStart, (state) => {
      state.loading = true;
      state.user = null;
    }),
    // immerOn(UserActions.createNewFirebaseUserSuccess, (state,{ user, username } ) => {
    //   state.loading = false;
    //   const newUser = new User(user.user.uid, username, user.user?.email ?? '', user.user.emailVerified, user.user?.displayName ?? undefined);
    //   state.user = newUser;
    // }),
    immerOn(UserActions.createNewFirebaseUserFailure, (state, { error }) => {
      state.error = error;
      state.loading = false;
      state.user = null;
    }),
    immerOn(UserActions.createNewBackendUserSuccess, (state, { user }) => {
      state.error = null;
      state.loading = false;
      state.user = user;
    }),
    immerOn(UserActions.createNewBackendUserFailure, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),

    immerOn(UserActions.createNewUserWithGoogleStart, (state) => {
      state.loading = true;
      state.user = null;
    }),

    immerOn(UserActions.createNewUserWithAppleStart, (state) => {
      state.loading = true;
      state.user = null;
    }),

    immerOn(UserActions.loginStart, (state) => {
      state.error = null;
      state.loading = true;
      state.user = null;
    }),
    immerOn(UserActions.loginSuccess, (state) => {
      state.loading = false;
    }),
    immerOn(UserActions.loginFailure, (state, { error }) => {
      state.error = error;
      state.loading = false;
      // state.user = null;
    }),

    immerOn(UserActions.loginUserWithGoogleStart, (state) => {
      state.loading = true;
      state.user = null;
    }),
    immerOn(UserActions.loginUserWithAppleStart, (state) => {
      state.loading = true;
      state.user = null;
    }),
    immerOn(UserActions.loginUserWithPopupSuccess, (state) => {
      state.loading = false;
      state.error = null;
    }),
    immerOn(UserActions.loginUserWithPopupFail, (state, { error }) => {
      state.loading = false;
      state.user = null;
      state.error = error;
    }),

    immerOn(UserActions.getBackendUserFailure, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),
    immerOn(UserActions.getBackendUserSuccess, (state, { user }) => {
      state.error = null;
      state.loading = false;
      state.user = user;
      state.profileNotification = false;
    }),

    immerOn(UserActions.logoutFirebaseUserStart, (state) => {
      state.error = null;
      state.loading = true;
    }),
    immerOn(UserActions.logoutFirebaseUserSuccess, (state) => {
      state.loading = false;
      state.user = null;
      localStorage.removeItem('lastTrophyCount');
    }),
    immerOn(UserActions.logoutFirebaseUserFail, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),

    immerOn(UserActions.updateUsername, (state, { username }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateFirstname, (state, { firstname }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateLastname, (state, { lastname }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateUserFootballRole, (state, { userFootballRole }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateUserFootballPlayerPosition, (state, { userFootballPlayerPosition }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateEmailAddress, (state, { email }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateUserYearOfBirth, (state, { yearOfBirth }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateUserLanguage, (state, { language }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateUserNotificationSettings, (state, { notificationSettings }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.updateFavouriteClub, (state, { favClubId }) => {
      state.error = null;
      state.loading = true;
    }),

    immerOn(UserActions.deleteAccountStart, (state) => {
      state.error = null;
      state.loading = true;
    }),
    immerOn(UserActions.deleteAccountSuccess, (state) => {
      state.loading = false;
      state.user = null;
    }),
    immerOn(UserActions.deleteAccountFail, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),

    immerOn(UserActions.uploadProfileImageStart, (state) => {
      state.error = null;
      state.loading = true;
    }),
    immerOn(UserActions.uploadProfileImageSuccess, (state, { imageUrl }) => {
      state.loading = false;
    }),
    immerOn(UserActions.uploadProfileImageFail, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),

    immerOn(UserActions.uploadProfileImageStart, (state) => {
      state.error = null;
      state.loading = true;
    }),
    immerOn(UserActions.uploadProfileImageSuccess, (state, { imageUrl }) => {
      state.loading = false;
      // TODO profile Image aus store loeschen
    }),
    immerOn(UserActions.uploadProfileImageFail, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),

    immerOn(UserActions.resetPasswordStart, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.resetPasswordSuccess, (state) => {
      state.loading = false;
    }),
    immerOn(UserActions.resetPasswordFail, (state) => {
      state.loading = false;
    }),

    immerOn(UserActions.verifyAndConfirmPasswordResetStart, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.verifyAndConfirmPasswordResetSuccess, (state) => {
      state.loading = false;
    }),
    immerOn(UserActions.verifyAndConfirmPasswordResetFail, (state) => {
      state.loading = false;
    }),

    immerOn(UserActions.updateBackendUserStart, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.updateBackendUserSuccess, (state, { user }) => {
      state.loading = false;
      state.user = user;
    }),
    immerOn(UserActions.updateBackendUserFail, (state) => {
      state.loading = false;
    }),

    immerOn(UserActions.updatePasswordStart, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.updatePasswordSuccess, (state) => {
      state.loading = false;
    }),
    immerOn(UserActions.updatePasswordFail, (state) => {
      state.loading = false;
    }),

    immerOn(UserActions.setProfileNotification, (state, { profileNotification }) => {
      state.loading = true;
      state.profileNotification = profileNotification;
    }),
    immerOn(UserActions.setProfileNotificationSuccess, (state) => {
      state.loading = false;
    }),
    immerOn(UserActions.getProfileNotification, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.getProfileNotificationSuccess, (state, { profileNotification }) => {
      state.profileNotification = profileNotification;
      state.loading = false;
    }),

    immerOn(UserActions.setNewUserInvitationLink, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.setNewUserInvitationLinkSuccess, (state, { user }) => {
      state.user = user;
      state.loading = false;
    }),
    immerOn(UserActions.setNewUserInvitationLinkFail, (state) => {
      state.loading = false;
    }),

    immerOn(UserActions.setUserDeviceToken, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.setUserDeviceTokenSuccess, (state, { deviceToken, fcmToken }) => {
      state.deviceToken = deviceToken;
      state.fcmToken = fcmToken;
      state.loading = false;
    }),
    immerOn(UserActions.setUserDeviceTokenFail, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),

    immerOn(UserActions.deleteUserDeviceToken, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.deleteUserDeviceTokenSuccess, (state) => {
      state.deviceToken = null;
      state.loading = false;
      state.fcmToken = null;
    }),
    immerOn(UserActions.deleteUserDeviceTokenFail, (state, { error }) => {
      state.error = error;
      state.loading = false;
    }),
    immerOn(UserActions.setLandingAlreadyVisitedToken, (state, { alreadyVisited }) => {
      state.loading = true;
      state.landingAlreadyVisited = alreadyVisited;
    }),
    immerOn(UserActions.setLandingAlreadyVisitedTokenSuccess, (state) => {
      state.loading = false;
    }),

    immerOn(UserActions.retrieveLandingAlreadyVisitedToken, (state) => {
      state.loading = true;
    }),
    immerOn(UserActions.retrieveLandingAlreadyVisitedTokenSuccess, (state, { alreadyVisited }) => {
      state.loading = false;
      state.landingAlreadyVisited = alreadyVisited;
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectUserState,
  selectLoading,
  selectUser,
  selectError,
  selectProfileNotification,
  selectDeviceToken,
  selectLandingAlreadyVisited,
  selectFcmToken,
} = userFeature;

export const selectIsLoggedIn = createSelector(selectUser, (user) => {
  return Boolean(user);
});

export const userVm = createSelector(
  selectUser,
  selectUserState,
  selectLoading,
  selectError,
  selectIsLoggedIn,
  selectProfileNotification,
  selectLandingAlreadyVisited,
  (user, userState, loading, error, isLoggedIn, profileNotification, landingAlreadyVisited) => {
    const vm = {
      user,
      userState,
      loading,
      error,
      isLoggedIn,
      profileNotification,
      landingAlreadyVisited,
    };
    return vm;
  },
);
