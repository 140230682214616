import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserFacade } from '@kiq/client/data-access/user';
import { MODAL_REF } from '@kiq/shared/util/modal';
import { CustomTitleStrategyService } from '@kiq/client/data-access/config';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/client/util/translation';

@Component({
  selector: 'kiq-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'authentication',
        loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
      },
    },
  ],
})
export class PasswordResetDialogComponent implements OnInit {
  fb = inject(FormBuilder);
  userFacade = inject(UserFacade);
  private readonly modalRef = inject(MODAL_REF);
  private readonly titleService = inject(CustomTitleStrategyService);

  pwResetForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  ngOnInit() {
    this.titleService.setTitle('Passwort Reset Dialog');
  }

  closeDialog() {
    this.modalRef.close();
  }

  resetPassword() {
    if (this.pwResetForm.controls['email'].value) {
      this.userFacade.resetPassword(this.pwResetForm.controls['email'].value);
      this.modalRef.close();
    }
  }
}
