export const serializePayload = (value: any): any => {
  if (value instanceof Map) {
    return Object.fromEntries(value);
  } else if (Array.isArray(value)) {
    return value.map(serializePayload);
  } else if (value !== null && typeof value === 'object') {
    return Object.keys(value).reduce((acc, key) => {
      acc[key] = serializePayload(value[key]);
      return acc;
    }, {} as any);
  }

  // If it's a primitive value, return it as is
  return value;
}
