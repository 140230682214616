import { Authentication } from './authentication.interface';
import { from, of, skip, switchMap, take } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Auth, signInWithCustomToken, user, authState } from '@angular/fire/auth';
import { UserFacade } from '@kiq/client/data-access/user';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';

@Injectable()
export class AppAuthenticationService implements Authentication {
  private readonly nativeAppService = inject(NativeAppsService);
  private readonly userFacade = inject(UserFacade);
  private readonly auth = inject(Auth);

  readonly authChanges$ = authState(this.auth);
  readonly token$ = user(this.auth).pipe(switchMap((user) => (user ? from(user.getIdToken()) : of(null))));

  login(): void {
    this.nativeAppService.openLoginModal();
  }

  logout(): void {
    this.userFacade.logout();
    this.authChanges$.pipe(skip(1), take(1)).subscribe((user) => this.nativeAppService.logout());
  }

  register(): void {
    this.nativeAppService.openRegisterModal();
  }

  signInWithCustomToken(token: string) {
    signInWithCustomToken(this.auth, token)
      .then(() => this.userFacade.getBackendUser())
      .catch((error) => {
        console.error('Login mit bestehendem Custom Token fehlgeschlagen:', error);
        this.logout();
      });
  }

  initializeAppWithCustomToken(token: string) {
    return signInWithCustomToken(this.auth, token).catch(() => this.logout());
  }
}
