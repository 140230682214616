import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { AVAILABLE_LANGS, AvailableLanguages } from '@kiq/client/util/translation';
import { ConfigFacade } from '@kiq/client/data-access/config';

@Injectable({
  providedIn: 'root',
})
export class LanguageInitializerService {
  private readonly configFacade = inject(ConfigFacade);
  languageFeatureFlag = this.configFacade.languageFeatureFlag;

  constructor(private translocoService: TranslocoService) {}

  initializeLanguage(): Promise<void> {
    return new Promise((resolve) => {
      // Detect browser language
      const browserLang = navigator.language.split('-')[0]; // e.g., 'en-US' -> 'en'
      const availableLangs = AVAILABLE_LANGS;
      let defaultLang: AvailableLanguages | string = AvailableLanguages.DE;

      if (this.languageFeatureFlag()) {
        defaultLang = availableLangs.find((language) => language.toString() === browserLang)
          ? browserLang
          : AvailableLanguages.DE;
      }

      // Set the default language dynamically
      this.translocoService.setDefaultLang(defaultLang);
      this.translocoService.setActiveLang(defaultLang);

      resolve();
    });
  }
}
