import { inject, Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { exhaustMap, take } from 'rxjs';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { TranslationFacade } from 'shared/data-access/translation-settings';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN) as string;
  translationFacade = inject(TranslationFacade);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.translationFacade.selectLanguageSettings$.pipe(
      take(1),
      exhaustMap((language) => {
        if (!language) {
          return next.handle(req);
        } else {
          if (!language || !req.url.includes(this.BACKEND_BASE_URL)) {
            return next.handle(req);
          }
          const modifiedReq = req.clone({
            headers: req.headers
              .set('x-kikkz-UserLanguage', `${language}`)
              .set('Cache-Control', 'no-cache, no-store, must-revalidate'),
          });
          return next.handle(modifiedReq);
        }
      }),
    );
  }
}
