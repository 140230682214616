import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslationActions } from './translation.actions';
import { selectLanguageSettings } from './translation.reducer';

@Injectable()
export class TranslationFacade {
  private store = inject(Store);
  selectLanguageSettings$ = this.store.select(selectLanguageSettings);

  setSelectedLanguage(selectedLanguage: string | null) {
    this.store.dispatch(TranslationActions.setLanguageSettingsStart({ languageSettings: selectedLanguage }));
  }

  getLastLanguageSettingsFromUser() {
    this.store.dispatch(TranslationActions.retrieveLanguageSettingsStart());
  }
}
