import { catchError, from, Observable, of, switchMap, take, tap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { UserFacade } from '@kiq/client/data-access/user';
import { Auth } from '@angular/fire/auth';
import { AppAuthenticationService, AuthenticationService } from '@kiq/shared/data-access/authentication';
import { CUSTOM_TOKEN_QUERY_PARAM } from '@kiq/client/util/routing';

export function initializeApplication(
  actions: Actions,
  facade: UserFacade,
  auth: Auth,
  authenticatioService: AuthenticationService | AppAuthenticationService,
) {
  return () => {
    const queryParams = new URLSearchParams(window.location.search);

    const customToken = queryParams.get(CUSTOM_TOKEN_QUERY_PARAM);

    let obs$: Observable<unknown> = of(null);

    if (customToken && authenticatioService instanceof AppAuthenticationService) {
      obs$ = from((authenticatioService as AppAuthenticationService).initializeAppWithCustomToken(customToken));
    }

    return obs$.pipe(
      catchError((error) => of(null)),
      switchMap(() => {
        return authenticatioService.token$.pipe(take(1));
      }),
      tap((token) => {
        if (!token) {
          return;
        }
        facade.getBackendUser();
      }),
      switchMap((token) => {
        if (!token) {
          return of(true);
        }

        return actions.pipe(ofType(facade.getBackendUserSuccess, facade.getBackendUserFail), take(1));
      }),
    );
  };
}
