import { AsyncTicTacToeQuestion } from './async-tic-tac-toe-question';
import { GameTopscorerQuestion } from './game-topscorer-question';

export type AsyncQuestion = {
  maxAnswerDurationSeconds: number;

  // TTT gameType
  ticTacToe?: AsyncTicTacToeQuestion;

  //Topscore gametype
  topscorer?: GameTopscorerQuestion;
};
