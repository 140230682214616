export const clientRoutes = {
  HOME: '',
  LANDING: 'home',
  GAMES: {
    BASE: 'game',
    WHO_IS_IT: 'who-is-it',
    TIC_TAC_TOE: 'tic-tac-toe',
    GAME_PRINCIPLE: 'game-principle',
    SINGLE_PLAYER_GAME: 'single-player-game',
    CREATE_LINK: 'create-link',
  },
  AUTH: {
    BASE: 'auth',
    LOGIN: 'login',
    REGISTER: 'register',
    EMAIL_ACTION: 'email-action',
  },
  HEADER: {
    BASE: 'policy',
    IMPRINT: 'imprint',
    PRIVACY_POLICY: 'privacy-policy',
    AGB: 'agb',
  },
  PROFILE: {
    BASE: 'profile',
    PROFILE_SETTINGS: 'settings',
    CHANGE_PROFILE_SETTINGS: 'change-profile-settings',
    STATISTICS: {
      BASE: 'statistics-versus',
      USER: 'user',
    },
  },
  Ranking: {
    BASE: 'ranking',
    Username: 'user',
  },
  User: {
    BASE: 'user',
  },
  MORE: {
    BASE: 'more',
    LANGUAGE_SETTINGS: 'language',
    NOTIFICATIONS_SETTINGS: 'notification',
  },
};

export const wordpressRoutes = {
  IMPRINT: 'https://kikkz.de/impressum/',
  AGB: 'https://kikkz.de/agb/',
  PRIVACY_POLICY: 'https://kikkz.de/datenschutzerklaerung/',
};

export const socialMediaRoutes = {
  INSTAGRAM: 'https://www.instagram.com/kikkz.de/',
  TIKTOK: 'https://www.tiktok.com/@kikkz.de/',
  GOOGLE: 'https://www.google.com/search?q=kikkz.de',
};

export const GAME_TYPE_QUERY_PARAM = 'gameType';
export const INVITE_QUERY_PARAM = 'invite';
export const GAME_ID_QUERY_PARAM = 'id';
export const START_QUERY_PARAM = 'start';
export const GAME_MODE_QUERY_PARAM = 'gameMode';
export const COMPETITIONS_QUERY_PARAM = 'clubs';
export const ANSWER_OPTIONS_QUERY_PARAM = 'noAnswOpts';
export const DIFFICULTY_QUERY_PARAM = 'diff';
export const PLAYER_MODE_QUERY_PARAM = 'playerMode';
export const WIN_MODE_QUERY_PARAM = 'winMode';
export const PARTNER_QUERY_PARAM = 'partner';
export const MAX_GAME_DURATION_IN_SECONDS_QUERY_PARAM = 'maxGameDuration';
export const MAX_TURN_DURATION_IN_SECONDS_QUERY_PARAM = 'maxTurnDuration';
export const CUSTOM_TOKEN_QUERY_PARAM = 'customToken';
export const NOTIFICATION_ACTION_TYPE_QUERY_PARAM = 'notificationActionType';
export const NOTIFICATION_GAME_ID_QUERY_PARAM = 'notificationGameId';
