import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { NotificationActions } from './notification.actions';
import { AppNotificationService } from '../service/app-notification.service';

@Injectable()
export class NotificationEffects {
  private readonly actions = inject(Actions);
  private readonly store = inject(Store);
  private readonly appNotificationService = inject(AppNotificationService);

  saveLanguageSettingsToLocalStorage = createEffect(() => {
    return this.actions.pipe(
      ofType(NotificationActions.setNotificationModalTokenStart),
      map(({ notificationsModalShown }) => {
        this.appNotificationService.setNotificationsModalTokenToLocalStorage(notificationsModalShown);
        return NotificationActions.setNotificationModalTokenSuccess({ notificationsModalShown });
      }),
      catchError((err) => {
        return of(NotificationActions.setNotificationModalTokenFail());
      }),
    );
  });

  retrieveLanguageSettings = createEffect(() => {
    return this.actions.pipe(
      ofType(NotificationActions.retrieveNotificationModalTokenStart),
      concatMap(() => {
        return this.appNotificationService.getNotificationsModalTokenFromLocalStorage().pipe(
          map((notificationsModalShown) => {
            if (!notificationsModalShown) {
              return NotificationActions.retrieveNotificationModalTokenFail();
            }
            return NotificationActions.retrieveNotificationModalTokenSuccess({ notificationsModalShown });
          }),
          catchError((err) => {
            return of(NotificationActions.retrieveNotificationModalTokenFail());
          }),
        );
      }),
    );
  });
}
