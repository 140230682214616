export enum AsyncState {
  USER_TURN = 'play',
  CHALLENGED = 'challenged',
  OPPONENT_TURN = 'opponentTurn',
  TIME_IS_UP = 'timeIsUp',
  USER_GAVE_UP = 'userGaveUp',
  OPPONENT_GAVE_UP = 'opponentGaveUp',
  DRAW = 'draw',
  USER_LOST = 'userLost',
  USER_WON = 'userWon',
}
