import { catchError, of, switchMap, take, tap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { UserFacade } from '@kiq/client/data-access/user';
import { Auth } from '@angular/fire/auth';
import { AppAuthenticationService, AuthenticationService } from '@kiq/shared/data-access/authentication';

export function initializeApplication(
  actions: Actions,
  facade: UserFacade,
  auth: Auth,
  authenticatioService: AuthenticationService | AppAuthenticationService,
) {
  return () => {
    return authenticatioService.token$.pipe(
      take(1),
      tap((token) => {
        if (!token) {
          return;
        }
        facade.getBackendUser();
      }),
      switchMap((token) => {
        if (!token) {
          return of(true);
        }

        return actions.pipe(ofType(facade.getBackendUserSuccess, facade.getBackendUserFail), take(1));
      }),
      catchError((error) => of(true)),
    );
  };
}
