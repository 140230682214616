import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { SnackbarService, SnackbarType } from 'shared/util/snackbar';
import { UserFacade } from '@kiq/client/data-access/user';
import { TranslationService } from '../service/translation.service';
import { TranslationActions } from './translation.actions';
import { Store } from '@ngrx/store';
import { TranslationFacade } from './translation.facade';
import { catchError, concatMap, map, of } from 'rxjs';
import { selectLanguageSettings } from './translation.reducer';
import { translate } from '@jsverse/transloco';

@Injectable()
export class TranslationEffects {
  private readonly actions = inject(Actions);
  private readonly store = inject(Store);
  private readonly snackbarService = inject(SnackbarService);
  private readonly userFacade = inject(UserFacade);
  private readonly translationFacade = inject(TranslationFacade);
  private readonly translationService = inject(TranslationService);

  setLanguageSettings = createEffect(() => {
    return this.actions.pipe(
      ofType(TranslationActions.setLanguageSettingsStart),
      concatLatestFrom(() => [this.store.select(selectLanguageSettings)]),
      map(([{ languageSettings }, previousLanguageSettings]) => {
        this.translationService.setSelectedLanguage(languageSettings);
        return TranslationActions.setLanguageSettingsSuccess({ languageSettings });
      }),
      catchError((err) => {
        this.snackbarService.show({
          message: translate('translationDataAccess.errorSavingLanguage'),
          type: SnackbarType.ERROR,
        });
        return of(TranslationActions.setLanguageSettingsFail());
      }),
    );
  });

  retrieveLanguageSettings = createEffect(() => {
    return this.actions.pipe(
      ofType(TranslationActions.retrieveLanguageSettingsStart),
      concatLatestFrom(() => this.userFacade.user$),
      concatMap(([, user]) => {
        if (!user || !user.language) {
          return this.translationService.getActiveBrowserLanguage().pipe(
            map((languageSettings) => {
              if (!languageSettings) {
                return TranslationActions.retrieveLanguageSettingsFail();
              }
              return TranslationActions.retrieveLanguageSettingsSuccess({ languageSettings });
            }),
            catchError((err) => {
              this.snackbarService.show({
                message: translate('translationDataAccess.errorGettingPreviousLanguage'),
                type: SnackbarType.ERROR,
              });
              return of(TranslationActions.retrieveLanguageSettingsFail());
            }),
          );
        }
        return this.translationService.getSelectedLanguageFromUser(user).pipe(
          map((languageSettings) => {
            if (!languageSettings) {
              return TranslationActions.retrieveLanguageSettingsFail();
            }
            return TranslationActions.retrieveLanguageSettingsSuccess({ languageSettings });
          }),
          catchError((err) => {
            this.snackbarService.show({
              message: translate('translationDataAccess.errorGettingPreviousLanguage'),
              type: SnackbarType.ERROR,
            });
            return of(TranslationActions.retrieveLanguageSettingsFail());
          }),
        );
      }),
    );
  });
}
