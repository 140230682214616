import { inject, Injectable } from '@angular/core';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { Trophy } from '@kiq/shared/types';
import { TrophyTypeOrder } from 'helper';
import { UserTrophyType } from '@kiq/shared/enums';

@Injectable()
export class TrophyService {
  private readonly http = inject(HttpClient);
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_TROPHY_URL = `${this.BACKEND_BASE_URL}/api/v1/user/trophies`;

  private static getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  getTrophies$(userId: string): Observable<Array<Trophy>> {
    const httpOptions = TrophyService.getHeader();
    const url = `${this.BACKEND_TROPHY_URL}/${userId}`;

    return this.http.get<Array<Trophy>>(url, { ...httpOptions }).pipe(
      map((trophies) => {
        //sorts trophies based on its types using pre-defined TrophyTypeOrder
        return trophies.sort((a, b) => {
          const aPriority = TrophyTypeOrder[a.type] || 999;
          const bPriority = TrophyTypeOrder[b.type] || 999;
          return aPriority - bPriority;
        });
      }),
    );
  }

  retrieveLastTrophyCountFromLocalStorage$(): Observable<number | null> {
    const lastTrophyCountString = localStorage.getItem('lastTrophyCount');
    const lastTrophyCount = lastTrophyCountString !== null ? Number(lastTrophyCountString) : 0;
    return of(lastTrophyCount);
  }

  setLastTrophyCountToLocalStorage(trophyCounterNotification: number | null) {
    if (trophyCounterNotification) {
      localStorage.setItem('lastTrophyCount', JSON.stringify(trophyCounterNotification));
    }
  }
}
