import { Authentication } from './authentication.interface';
import { InGameModalConfig, ModalConfig, RedirectUrlWithParams } from '@kiq/shared/types';
import { LoginComponent, RegisterComponent } from '@kiq/client/feature/auth';
import { inject, Injectable } from '@angular/core';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { InGameModalService, ModalService } from '@kiq/shared/util/modal';
import { from, of, switchMap } from 'rxjs';
import { FirebaseService, UserFacade } from '@kiq/client/data-access/user';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class AuthenticationService implements Authentication {
  private readonly configFacade = inject(ConfigFacade);
  private readonly modalService = inject(ModalService);
  private readonly userFacade = inject(UserFacade);
  private readonly firebaseService = inject(FirebaseService);
  private readonly actions = inject(Actions);

  readonly authChanges$ = this.firebaseService.authChanges$;
  readonly token$ = this.firebaseService.firebaseUser$.pipe(
    switchMap((user) => (user ? from(user.getIdToken()) : of(null))),
  );

  constructor() {
    this.actions.pipe(ofType(this.userFacade.getBackendUserFail)).subscribe(() => this.logout());
  }

  login(inGameModalService?: InGameModalService, redirectUrlWithParams?: RedirectUrlWithParams): void {
    if (inGameModalService?.hasModalOpen) {
      const inGameModalConfig: InGameModalConfig = {
        component: LoginComponent,
        isFullModalHeight: true,
        inputs: { redirectUrlWithParams },
      };

      inGameModalService?.updateInGameModal(inGameModalConfig);
    } else {
      const modalConfig: ModalConfig = {
        component: LoginComponent,
        useFullscreenModal: this.configFacade.useFullscreenModal(),
        inputs: { redirectUrlWithParams },
      };

      this.modalService.openNestedModal(modalConfig);
    }
  }

  register(inGameModalService?: InGameModalService, redirectUrlWithParams?: RedirectUrlWithParams): void {
    if (inGameModalService?.hasModalOpen) {
      const inGameModalConfig: InGameModalConfig = {
        component: RegisterComponent,
        isFullModalHeight: true,
        inputs: { redirectUrlWithParams },
      };

      inGameModalService?.updateInGameModal(inGameModalConfig);
    } else {
      const modalConfig: ModalConfig = {
        component: RegisterComponent,
        useFullscreenModal: this.configFacade.useFullscreenModal(),
        inputs: { redirectUrlWithParams },
      };

      this.modalService.openNestedModal(modalConfig);
    }
  }

  logout(): void {
    this.userFacade.logout();
  }
}
