export const environment = {
  production: false,
  API: {
    BASE: 'https://dev.api.kikkz.de',
    TTT_FEATURE_FLAG: true,
    TOPSCORER_FEATURE_FLAG: true,
    MIXED_ZONE_FEATURE_FLAG_TOKEN: true,
    FAKE_OR_FACT_FEATURE_FLAG_TOKEN: true,
    WII_FEATURE_FLAG: true,
    NATIONS_FEATURE_FLAG: true,
    TROPHIES_FEATURE_FLAG: true,
    AD: {
      LOADING_SCREEN_FEATURE_FLAG: true,
      GAMES_OVERVIEW_FEATURE_FLAG: true,
    },
    BULI_LICENSE_FEATURE_FLAG: true,
    ASYNCHRON_FEATURE_FLAG: true,
    LANGUAGE_FEATURE_FLAG: true,
    NOTIFICATION_FEATURE_FLAG: true,
    NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG: true,
    KIKKZ_APP_FEATURE_FLAG: true,
    DATA_SINCE_1990_FEATURE_FLAG: false,
    TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG: true,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCoNDNE2QJq9QNGMLiqAi38A01Gm0whkh0',
    authDomain: 'kikkz-dev.firebaseapp.com',
    projectId: 'kikkz-dev',
    storageBucket: 'kikkz-dev.appspot.com',
    messagingSenderId: '1089688717798',
    appId: '1:1089688717798:web:9bdb82c3a04f0b51be2e27',
  },
};
