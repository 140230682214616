<ng-container *transloco="let t; prefix: 'authentication.register'">
  <div class="background-stripes with-dark-gradient bg-cover bg-no-repeat" id="register">
    <div class="bg-primary border-b border-surfaceSecondary absolute top-0 w-full">
      <div class="p-4 h-12 max-w-default flex items-center">
        <button class="h-6 w-6" (click)="closeDialog()">
          <shared-icon class="icon-wrapper" name="close-round"></shared-icon>
        </button>
        <p class="text-textPrimary mx-auto brand text-base">{{ t("register") }}</p>
      </div>
    </div>

    <div class="relative overflow-y-auto px-4 py-4 mt-[48px] bg-no-repeat bg-cover bg-center custom-height">
      <h1 class="text-textPrimary brand mb-4 sm:text-center uppercase"
        >{{ t("showYourKnowledge") }}<span class="text-surfaceBrand normal-case"> {{ t("footballKnowledge") }}</span>
      </h1>
      <form [formGroup]="registerForm">
        <div>
          <div class="flex flex-col gap-y-2 w-full">
            <kiq-text-input
              class="w-full"
              [formControlName]="'email'"
              [inputType]="'email'"
              (keydown.enter)="focus.set(2)"
              autocomplete="username"
              placeholder="{{ t('mailPlaceholder') }}"></kiq-text-input>
            <kiq-text-input
              class="w-full"
              [shouldFocus]="focus() === 2"
              [formControlName]="'password'"
              [inputType]="'password'"
              (keydown.enter)="focus.set(3)"
              autocomplete="new-password"
              placeholder="{{ t('passwordPlaceholder') }}"></kiq-text-input>
            <kiq-text-input
              class="w-full"
              #userName
              [formControlName]="'username'"
              [shouldFocus]="focus() === 3"
              (keydown.enter)="focusOut.set(true); focus.set(4); openClubSearch()"
              placeholder="{{ t('usernamePlaceholder') }}">
            </kiq-text-input>
            <kiq-input-button
              [formControlName]="'favouriteClub'"
              [placeholder]="t('favoriteClubPlaceholder')"
              (click)="openClubSearch()"></kiq-input-button>
            <div class="flex gap-4 items-center">
              <kiq-checkbox formControlName="privacyPolicy"></kiq-checkbox>
              <div>
                <p class="flex flex-row flex-wrap gap-1 text-white py-2">
                  {{ t("accept") }}
                  <span class="cursor-pointer text-white underline">
                    <a class="text-white" (click)="openUrl(WP_ROUTES.AGB)">{{ t("agb") }}</a> </span
                  >{{ t("and") }}
                  <span class="cursor-pointer text-white underline">
                    <a class="text-white" (click)="openUrl(WP_ROUTES.PRIVACY_POLICY)">{{
                      t("privacyPolicy")
                    }}</a> </span
                  >{{ t("read") }}
                </p>
              </div>
            </div>
            <div class="w-full h-14">
              <client-button
                [loading]="(loading$ | async) ?? undefined"
                [buttonType]="'primary'"
                [disabled]="registerForm.invalid"
                (click)="register()">
                <p class="large">{{ t("register") }}</p>
              </client-button>
            </div>
          </div>
        </div>
      </form>
      <div class="flex flex-col text-white mt-2 items-center gap-y-4 justify-center">
        <p>{{ t("orWith") }}</p>
        <div class="w-full h-14">
          <client-button [buttonType]="'secondary'" (click)="registerWithGoogle()">
            <div class="flex items-center gap-x-2">
              <img class="h-6 w-6 object-contain" [src]="GOOGLE_LOGO" />
              <p class="large">Google</p>
            </div>
          </client-button>
        </div>
        <p
          >{{ t("alreadyRegistered") }}
          <a class="underline pl-2 cursor-pointer" (click)="openLogin()">{{ t("login") }} </a>
        </p>
      </div>
    </div>
  </div>
</ng-container>
