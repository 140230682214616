import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { OnlineStatisticsActions } from './online-statistics.actions';
import {
  UserGameStatisticOpponentDetail,
  UserGameStatisticOpponentOverview,
  UserGameStatisticOverview,
} from '@kiq/shared/types';
import { BasicUserView, UserOwnerView } from '@kiq/shared/classes';
import { UserStatisticGameType } from '@kiq/shared/enums';

export interface OnlineStatisticsState {
  overviewLoading: boolean;
  allStatisticsLoading: boolean;
  otherUserLoading: boolean;
  userGameStatisticOverview: UserGameStatisticOverview | null;
  allUserGameStatisticOpponentOverviews: Array<UserGameStatisticOpponentOverview> | null;
  userGameStatisticOpponentDetails: Array<UserGameStatisticOpponentDetail> | null;
  otherUser: UserOwnerView | BasicUserView | null;
  userGameStatisticOpponentOverview: UserGameStatisticOverview | null;
}

export const initialOnlineStatisticsState: OnlineStatisticsState = {
  overviewLoading: false,
  allStatisticsLoading: false,
  otherUserLoading: false,
  userGameStatisticOverview: null,
  allUserGameStatisticOpponentOverviews: null,
  userGameStatisticOpponentDetails: null,
  otherUser: null,
  userGameStatisticOpponentOverview: null,
};

export const onlineStatisticsFeature = createFeature({
  name: 'onlineStatistics',

  reducer: createReducer(
    initialOnlineStatisticsState,
    //Add immerOn
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewStart, (state) => {}),
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewSuccess, (state, { userGameStatisticOverview }) => {
      state.userGameStatisticOverview = userGameStatisticOverview;
      state.overviewLoading = false;
    }),
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewFail, (state) => {
      state.overviewLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getOtherUserGameStatisticOverviewStart, (state) => {}),
    immerOn(
      OnlineStatisticsActions.getOtherUserGameStatisticOverviewSuccess,
      (state, { userGameStatisticOpponentOverview }) => {
        state.userGameStatisticOpponentOverview = userGameStatisticOpponentOverview;
        state.overviewLoading = false;
      },
    ),
    immerOn(OnlineStatisticsActions.getOtherUserGameStatisticOverviewFail, (state) => {
      state.overviewLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsStart, (state) => {}),
    immerOn(
      OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsSuccess,
      (state, { allUserGameStatisticOpponentOverviews }) => {
        state.allUserGameStatisticOpponentOverviews = allUserGameStatisticOpponentOverviews;
        state.allStatisticsLoading = false;
      },
    ),
    immerOn(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsFail, (state) => {
      state.allStatisticsLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsStart, (state, { otherUser }) => {
      state.overviewLoading = true;
    }),
    immerOn(
      OnlineStatisticsActions.getUserGameStatisticOpponentDetailsSuccess,
      (state, { userGameStatisticOpponentDetails }) => {
        const sortOrder = [
          UserStatisticGameType.ONLINE_MATCH,
          UserStatisticGameType.TTT,
          UserStatisticGameType.TTT_TRANSFER,
          UserStatisticGameType.TTT_NATIONS,
        ];

        const sortedDetails = [...userGameStatisticOpponentDetails].sort((a, b) => {
          return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
        });

        state.userGameStatisticOpponentDetails = sortedDetails;
        state.overviewLoading = false;
      },
    ),
    immerOn(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsFail, (state) => {
      state.overviewLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getOtherUserStart, (state, { userName }) => {
      state.otherUser = null;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserSuccess, (state, { otherUser }) => {
      state.otherUser = otherUser;
      state.otherUserLoading = false;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserFail, (state) => {
      state.otherUser = null;
      state.otherUserLoading = false;
    }),
    immerOn(OnlineStatisticsActions.showOverviewLoader, (state) => {
      state.overviewLoading = true;
    }),
    immerOn(OnlineStatisticsActions.showAllStatisticsLoader, (state) => {
      state.allStatisticsLoading = true;
    }),
    immerOn(OnlineStatisticsActions.showOtherUserLoader, (state) => {
      state.otherUserLoading = true;
    }),
  ),
});

export const {
  selectOverviewLoading,
  selectAllStatisticsLoading,
  selectOtherUserLoading,
  selectUserGameStatisticOverview,
  selectAllUserGameStatisticOpponentOverviews,
  selectUserGameStatisticOpponentDetails,
  selectUserGameStatisticOpponentOverview,
  selectOtherUser,
} = onlineStatisticsFeature;
