import { Component, DestroyRef, forwardRef, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { BaseInputComponent } from '../../base-input.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedUiIconModule } from 'shared/ui/icon';

@Component({
  selector: 'kiq-select-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CdkMenuTrigger, CdkMenu, CdkMenuItem, SharedUiIconModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
  templateUrl: './select-input.component.html',
  styleUrl: './select-input.component.scss',
})
export class SelectInputComponent extends BaseInputComponent implements OnInit {
  @Input({ required: true }) placeholder!: string;
  @Input({ required: true }) options!: Array<{ value: any; label: string }>;
  currentlySelectedOption: { value: any; label: string } | undefined;

  private readonly destroyRef = inject(DestroyRef);

  override ngOnInit() {
    super.ngOnInit();

    if (this.control.value) {
      const option = this.options.find((option) => option.value === this.control.value);
      if (option) {
        this.currentlySelectedOption = option;
      }
    } else {
      this.currentlySelectedOption = undefined;
    }

    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (value) {
        const option = this.options.find((option) => option.value === value);
        if (option) {
          this.currentlySelectedOption = option;
        }
      } else {
        this.currentlySelectedOption = undefined;
      }
    });
  }

  optionSelected(option: { value: any; label: string }) {
    this.control.setValue(option.value);
  }
}
