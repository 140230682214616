import { UserOwnerView } from '@kiq/shared/classes';
import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { NotificationActions } from './notification.actions';

export interface NotificationState {
  loading: boolean;
  user: UserOwnerView | null;
  error: string | null;
  notificationsModalToken: boolean;
}

export const initialNotificationState: NotificationState = {
  loading: false,
  user: null,
  error: null,
  notificationsModalToken: false,
};

export const notificationFeature = createFeature({
  name: 'notification',
  reducer: createReducer(
    initialNotificationState,

    immerOn(NotificationActions.setNotificationModalTokenStart, (state) => {
      state.loading = true;
    }),
    immerOn(NotificationActions.setNotificationModalTokenSuccess, (state, { notificationsModalShown }) => {
      state.notificationsModalToken = notificationsModalShown;
      state.loading = false;
    }),
    immerOn(NotificationActions.setNotificationModalTokenFail, (state) => {
      state.loading = false;
    }),

    immerOn(NotificationActions.retrieveNotificationModalTokenStart, (state) => {
      state.loading = true;
    }),
    immerOn(NotificationActions.retrieveNotificationModalTokenSuccess, (state, { notificationsModalShown }) => {
      state.notificationsModalToken = notificationsModalShown;
      state.loading = false;
    }),
    immerOn(NotificationActions.retrieveNotificationModalTokenFail, (state) => {
      state.loading = false;
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectLoading,
  selectError,
  selectNotificationsModalToken,
} = notificationFeature;
