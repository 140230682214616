export type AsyncModusProfessional = {
  name: string;
  description?: string;
  backgroundColor?: string;
  textColor?: string;
  instagram?: string;
  spotify?: string;
  tiktok?: string;
  twitch?: string;
  youtube?: string;
  imageUrl?: string;
  preview: boolean;
  duelRatio?: number;
};
