import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TrophyActions } from './trophy.actions';
import {
  selectIsTrophyCounterNotification,
  selectLoading,
  selectTrophies,
  selectTrophyCounter,
  selectTrophyCounterNotification,
} from './trophy.reducer';
import { Observable } from 'rxjs';
import { TrophyActionContext } from '@kiq/shared/enums';

@Injectable()
export class TrophyFacade {
  private store = inject(Store);
  selectTrophiesList$ = this.store.select(selectTrophies);
  trophyCounter$: Observable<number | null> = this.store.select(selectTrophyCounter);
  selectTrophyCounterNotification$: Observable<number | null> = this.store.select(selectTrophyCounterNotification);
  selectIsTrophyCounterNotification$: Observable<boolean> = this.store.select(selectIsTrophyCounterNotification);
  trophiesLoading$: Observable<boolean> = this.store.select(selectLoading);

  getTrophiesList(userId: string | null | undefined, trophyActionContext?: TrophyActionContext) {
    this.store.dispatch(TrophyActions.getTrophiesStart({ userId, trophyActionContext }));
  }

  getTrophyCountForUser() {
    this.store.dispatch(TrophyActions.getTrophyCountForUser());
  }

  setTrophyCounterNotification() {
    this.store.dispatch(TrophyActions.setTrophyCountNotification());
  }
}
